.list-tree{
  display: flex;
  flex-direction: column;

  padding-left: 0;
  margin-bottom: 0;

  &__item{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: $list-group-item-padding-y $list-group-item-padding-x;

    margin-bottom: $list-group-item-padding-y;
    background-color: $list-group-bg;
    border: $list-group-border-width solid $list-group-border-color;
  }
}