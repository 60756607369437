/**
* header
*/
.btn-select {

  cursor: pointer;

  width: 100%;
  // // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  // height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background-image: none;
  background-clip: padding-box;
  border: $input-btn-border-width solid $input-border-color;
  border-radius: $ui-input-border-radius;

  // Hover and focus styles are shared
  @include hover {
    color: $btn-icon-color;
    background-color: $ui-input-background;
    border-color: $ui-input-border-color;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0;
    background-color: darken($btn-icon-background, 10%);
    border-color: darken($btn-icon-border, 12%);
    color: $btn-icon-color;

  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    box-shadow: 0 0 0;
    color: $btn-icon-color;
    background-image: none;

    background-color: $ui-input-background;
    border-color: $ui-input-border-color;
  }

  .form-control-sm > & {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.15rem;
    font-weight: 300;

  }
}
.btn-group.bootstrap-select.form__select{
  min-height: 32px;
}
