.gma {

    &-side-container {
        color: $gma-color;
        position: fixed;
        top: 0;
        right: 0;
        margin-right: -1px;
        overflow: hidden;
        height: 100%;
    }

    &-inside-container {
        overflow: hidden;
        border: 1px solid #e0e0e0;
        border-left: 0;
    }

    &-heading {
        font-size: 120%;
        line-height: $gma-side-panel-heading;
        padding: 0 10px;
        border-bottom: 1px solid $gma-lines-color;
    }

    &-view {
        overflow-y: auto;
        height: calc( 100% - #{ $gma-side-panel-heading });
        padding-bottom: 20px;
    }

    &-btn-start {
        float: left;
        margin-top: 100px;
        transform: rotate(-90deg);
        border: 1px solid $gma-lines-color;
        padding: 10px;
        margin-right: -12px;
        font-size: 25px;
        cursor: pointer;
        background: $gma-background;
        box-shadow: -5px -5px 8px;
        z-index: 2;
    }

    &-menu-bar {
        float: left;
        border-left: 1px solid $gma-lines-color;
        background: $gma-background;
        height: 100%;
        width: $gma-side-panel-width;
        margin-right: -$gma-side-panel-width;
        transition: margin 0.5s;
        margin-bottom: 0 !important;

        &-show {
            margin-right: 0;
        }

        &-inside {
            width: 100% !important;
            margin-right: 0;
        }
    }

    &-overlay {
        position: relative;
        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,0.4);
        }
    }

    &-select-element {
        outline: 2px dashed red !important;
    }

    &-block-center {
        text-align: center;
        margin: auto;
    }

    &-picker-alert {
        position: fixed;
        top: 50px;
        left: 50px;
        right: 50px;
    }

    &-margin-top {
        margin-top: 20px;
    }

    &-margin-bottom {
        margin-bottom: 20px;
    }

    &-lesson-descriptor {
        padding-left: 10px;
        @include font-darker();

        &-new {
            font-size: 150%;
            color: $gma-list-item-new;
        }

        &-break {
            font-size: 150%;
            color: $gma-list-item-break;
        }

        &-done {
            font-size: 150%;
            color: $gma-list-item-done;
        }
    }
}
