/**
* left menu
*
*/

#navbar-left {
  transition: all .5s;
  width: $left-menu-width;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: $ui-left-menu-bg;
  box-shadow: $box-shadow;

  z-index: 1003;
  label {
    margin-bottom: 0;
    &.active {
      & > p {
        background-color: $ui-left-menu-active-background;
      }
    }

    &.checkLabel {
      background-color: $ui-left-menu-sub-bg;
      p {
        background-color: transparent;
        > i {
          color: #fff !important;
        }
        > span {
          color: #fff !important;
        }
      }
      .nav-link, .nav-head {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  .menu-aside__submenu__list {
    .nav-link, .nav-head {
      color: $ui-left-menu-sub-color !important;
      width: 100% !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    ul {
      list-style: none !important;
      padding-bottom: 2px;
      padding-left: 25px;

    }
    li {
      a.nav-head, a.nav-link {
        &:hover {

          color: #fff !important;
          text-decoration: underline !important;

        }
      }
    }
  }

  .nav-link, .nav-head {
    width: 200px;
    overflow: hidden;
    transition: width .5s;
    display: block;
    cursor: pointer;

    &.active {
      text-decoration: underline;
    }
  }

  .navbar-brand {
    display: block;
    padding: 0;
    margin: 0;
    background: $ui-left-menu-bg;
    overflow: hidden;
    height: 66px;
    padding-bottom: 10px;

    svg {
      width: $left-menu-width - 40 !important;
      height: $top-menu-height;
      margin-top: 0px;
      margin-left: 20px;
      transition: $transition-base;

      * {
        fill: $ui-left-menu-logo;
        fill-opacity: 1;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  .nav-pills {
    .nav-head,
    .nav-link {
      white-space: nowrap;
      font-size: $font-size-base*1;
      color: $ui-left-menu-text-color;
      transition: $transition-base;
      padding: 0.35em 1em;
      font-weight: $font-weight-base;
      border-radius: 5px;
      margin: 0 0 0 0.55em;

      i {
        color: $ui-left-menu-text-color;
        font-size: 20px;
        position: relative;
        margin-right: 8px;
        bottom: -1px;
        padding: 0 5px;
      }
      &:hover {
        background-color: $ui-left-menu-hover-color;
        color: $gray;//#263035;
        text-decoration: none;

      }
    }

    .nav-link {

      &:hover {
        //background-color:transparent;
        color: $gray;
      }

      &.active {
        background-color: $ui-left-menu-active-background;
        color: $ui-left-menu-active-color;
        text-decoration: none;

        i {
          color: $ui-left-menu-active-color;
        }
      }
    }
  }
  .menu-aside__submenu__list {
    .nav-link {
      &.active {
        background-color: transparent;
        text-decoration: underline;
      }
    }

  }
}

.navbar-left__search {
  background: $ui-left-menu-bg;
  border-bottom: 10px solid $ui-left-menu-bg;

  .form-control {
    background: transparent;
    border-radius: 0;
    border-width: 0;
    box-shadow: 0 0 0;
    padding-left: 8px;

    &::placeholder {
      color: $ui-left-menu-text-color;
    }
  }
  .btn-icon {
    background: transparent;
    border-width: 0;
    border-radius: 0;
    padding-left: 6px;
    transition: $transition-base;
  }
  > .input-group {
    padding: 0 1em;
    border-bottom: 1px solid $ui-left-menu-separator-color;
  }
}

//Menu bocznne, na zastadzie działania checkboxów
.wrapper__overlay{
  cursor: pointer;
}
.menu-aside {
  height: 100%;
  display: block;
  padding-bottom: 30px;
  background: $ui-left-menu-gradient-bg;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;

  & > ul {
    > .nav-link {
      min-height: 40px;
    }
  }
  &__item {
    flex: 0 0;
    &.active {
      background-color: $ui-left-menu-text-color;

      label {
        i, span {
          color: #fff !important;
        }
      }
    }
  }

  //style dla ukrytych checkboxów
  &__uncheck--submenu {
    position: absolute;
    top: -9999px;
    left: -9999px
  }

  &__check {

    &--submenu, &--main {
      position: absolute;
      top: -9999px;
      left: -9999px
    }

    &--main {
      &:checked {

        & ~ #navbar-top {
          transition: all .5s;
          margin-left: 62px;

        }
        & ~ #content-wrapper {
          transition: all .5s;
          margin-left: 62px;
        }

        & ~ #navbar-left {
          width: $left-menu-width-collapsed !important;
          @include media-breakpoint-up(md) {
            .nav-head:hover, .nav-link:hover {

              span {
                position: absolute;
                display: inline-block;
                left: 60px;
                padding: 5px 10px;
                background-color: $ui-left-menu-sub-bg;
                border-radius: 2px;
                box-shadow: $ui-box-shadow;
                color: $ui-left-menu-sub-color !important;

              }
            }
          }

          .navbar-left__search {

            .input-group {
              transition: width .5s;
              background: $ui-left-menu-bg;

              &.show-search {
                width: $left-menu-width;
              }
            }

          }

          .nav-head, .nav-link {
            width: 48px;
            margin-left: 0.4em;
            padding-left: 10px;

          }

          .menu-aside > .menu-aside__item {
            .menu-aside__submenu__list {
              ul {
                list-style: none;
                .nav-link {
                  background-color: transparent !important;
                  text-decoration: underline !important;
                }

              }
            }

            & > label > .nav-head,
            & > .nav-link {
              position: relative;
              width: 30px;
              overflow: hidden;
              transition: width .5s;

              span {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 100%;
                min-width: 200px;
                color: $gray;
                padding: 0.5em 0.75em;
                font-weight: $font-weight-base;
                background-color: $gray-lighter;
                display: inline-block;
                transition: opacity .5s;
              }

              &:hover {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                width: 100%;
                overflow: visible;
                background-color: #d6dee2;
                color: #56707c;
                transition: all .5s;

                span {
                  opacity: 1;
                  display: inline-block;
                  border-bottom-right-radius: 5px;
                  border-top-right-radius: 5px;
                }
              }
            }
          }
          @include media-breakpoint-up(lg){
            .navbar-brand {
              svg {
                margin-left: 7px;
                margin-top: 3px;
                width: $left-menu-width - 80 !important;
                > g.hidden-small * {
                  fill-opacity: 0;
                }
              }
            }
          }

        }

        & ~ #navbar-left .menu-aside__check--submenu:checked ~ .menu-aside__submenu__list {

          width: 460px;
          left: 62px !important;

        }

      }
    }

    &--submenu {
      & ~ .menu-aside__submenu__list {
        transition: left .5s;
        padding-top: 80px;
        list-style: none;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
        left: -270px;
        width: 260px;
        background-color: $ui-left-menu-sub-bg;
        & > li {
          & > .nav-link, & > .nav-head {

            padding: 4px 0 !important;
            font-size: $font-size-base * 1.2 !important;

          }
          ul {
            li {

            }
            .nav-link {
              font-size: $font-size-base !important;
              padding: 2px !important;
            }
          }

        }
      }
      &:checked {

        & ~ .menu-aside__submenu__list {
          //display: block;
          padding-top: 115px;
          padding-left: 20px;
          list-style: none;
          position: absolute;
          top: 0;
          color: #fff;
          bottom: 0;
          left: 220px;
          background-color: $ui-left-menu-sub-bg;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 460px;
          & > li {
            & > .nav-link, & > .nav-head {
              padding: 2px 0 !important;
              font-size: $font-size-base * 1.05 !important;

            }
            ul {
              li {

              }
              .nav-link {
                font-size: $font-size-base !important;
                padding: 2px 0 !important;
              }
            }

          }

          .menu-aside__item {
            .nav-link {
              color: #fff !important;
            }
          }
        }
      }
    }

  }
  // style dla wysuwanego submenu

  &__item {
    padding-left: 10px;
  }

  &__submenu {
    z-index: -1;

    .nav-link span {
      font-size: 14px;
      color: #fff;
    }

    &__header {
      color: #fff;
      font-size: 22px;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 300;
      padding-bottom: 20px;
      padding-top: 0;
      position: absolute;
      top: 74px;
      display: block;
      width: 100%;
      .menu-aside__lab.i--unchecked {
        float: right;
        margin-right: 40px;
        cursor: pointer;

      }

      & ~ .menu-aside__item {
        padding-left: 0;
      }
      & ~ li {
        padding-top: 7px;

      }
      & + li {
        //padding-top: 50px;

      }
    }
  }
  //close na wysuniętym submenu
  .menu-aside__label--unchecked {
    float: right;
    margin-right: 20px;
  }
}
.autocomplete-suggestion{
  cursor: default;
}