.input-group {





  .input-group-addon {
    background-color: transparent;
    padding: 0.5rem 0.5rem;

    &--naked {
      border-color: transparent;
    }
  }

  &--filters-clear{
    > .form-control{
      border-radius: $ui-input-border-radius !important;
      border-width: $ui-input-border-width;
      border-color: $ui-input-border-color;
    }

    .input-group-btn {
      > .btn-icon {
        margin-left: 4px !important;
        border-bottom-left-radius: $ui-btn-icon-border-radius !important;
        border-top-left-radius: $ui-btn-icon-border-radius !important;
      }
    }
  }

  .input-group-prepend {

    border: 0;
    padding: 0;
    margin: 0;

    &:not(:empty) {
      align-self: stretch;
      padding: $input-padding-y-sm $input-padding-x-sm;
      border-radius: $input-border-radius;
      border-width: $input-btn-border-width 0 $input-btn-border-width $input-btn-border-width;
      border-color: $input-border-color;
      border-style: solid;
    }
  }

  &-picker{
    .input-group-addon{
      i{
        font-size: 20px;
        line-height: 16px;
      }
    }
  }

}




.input-group:not(.input-group--filters-clear) > .input-group:not(:last-child) {
  .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right-width: 0;
  }
}
.input-group:not(.input-group--filters-clear) > .input-group:last-child {
  .input-group-addon:not(:last-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}