@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400&subset=latin-ext');


h1,h2,h3,h4{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
}

.text-gray{
  color: $gray-light;
}

.trumbowyg-colors {
  float: left;
  width: 25px !important;
  height: 25px !important;
  font-size: 0 !important;
  margin: 1px !important;

  &:hover {
    outline: 1px solid red;
  }
}

.ttext1 {
  color: red;
}
.ttext2 {
  color: green;
}
.ttext3 {
  color: blue;
}
.btext1 {
  background-color: yellow;
}
.btext2 {
  background-color: gray;
}
.btext3 {
  background-color: black;
}