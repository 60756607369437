.modal-body.complexModal {
  position: relative;
  padding-bottom: 0;
  padding-top: 0;


  .close {
    position: absolute;
    z-index: 1;
    right:15px;
    top:12px;
  }
  .close + form {
    clear: both;
    position: relative;
  }

  .card {
    box-shadow: 0 0 0;
    margin: 0 -15px;

  }

  .card-block {
    padding:0;
  }

  .form-group {
    margin: 0 !important;

    padding-left:10px !important;
    padding-right:10px !important;
    padding-bottom: 10px !important;

    &.bg-light {
      padding-top: 10px;
    }

    .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

  }
}