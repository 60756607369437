/**
* header
*/

.scrolled-up {
  #navbar-top {

    height: 38px;

    .navbar-nav {
      > .nav-item {
        padding-bottom: 0;
        padding-top: 0;

        .toggle-nav-left i,
        > a > i {
          line-height: 26px !important;
          font-size: 20px !important;
        }
      }
    }
  }
}

#navbar-top {
  height: $top-menu-height;
  padding: 0;
  box-shadow: $box-shadow;
  margin-left: $left-menu-width;
  transition: all .5s;
  flex-direction: row;
  background: $ui-top-menu-gradient-bg;

  &.collapsed-nav-left {
    margin-left: $left-menu-width-collapsed;
  }

  .navbar-nav {
    flex-direction: row;
    > .nav-item {
      cursor: pointer;
      padding: 7px;
      transition: $transition-base;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      .toggle-nav-left i,
      > a > i {
        color: $white;
        line-height: 28px;
        font-size: 24px;
        min-width: 28px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        transition: $transition-base;

        & + span {
          margin: 0 10px;
        }
      }

      .menu-aside__label--main {
        margin-bottom: 0;
      }
    }
  }

  .intro {
    padding: 3px $dropdown-item-padding-x 10px;
    border-bottom: $ui-state-light-bg solid 1px;
    margin-bottom: 6px;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);

    &__picture {
      background: $ui-state-light-bg;
      color: $ui-text-color;
      border-radius: 4px;
      i {
        display: block;
        font-size: 24px;
        text-align: center;
        line-height: 40px;
        height: 40px;
        width: 40px;
      }
    }

    &__info {
      span {
        padding-left: 10px;
        white-space: nowrap;
      }
    }
  }
}

.bell-notifications {
  position: relative;

  [data-bn-counter] {
    display: none;
    position: absolute;
    right: 2px;
    top: 5px;
    background: $ui-red;
    padding: 0;
    height: 14px;
    width: 14px;
    font-size: 10px;
    line-height: 14px;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif !important;
  }

  &--unread {

    &:before {

      content:'';

      position: absolute;
      top: 5px;
      right: 0px;
      height: 32px;
      width: 32px;
      border: 8px solid $ui-red;
      border-radius: 70px;
      -moz-animation: heartbit 1s ease-out;
      -moz-animation-iteration-count: infinite;
      -o-animation: heartbit 1s ease-out;
      -o-animation-iteration-count: infinite;
      -webkit-animation: heartbit 1s ease-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;

    }

    [data-bn-counter] {
      display: block;

    }
  }


  .dropdown-menu{
    width: 420px;
    max-width: 90vw;
    padding:  $grid-gutter-width-base/4 0;

    .dropdown-item{
      margin: 0 $grid-gutter-width-base/4;
      width: auto;
      font-size: $font-size-base * 0.8;
      padding:  $grid-gutter-width-base/6 $grid-gutter-width-base/3;

      .ico {
        line-height: $font-size-base * 0.8;
        font-size: $font-size-base*1.2;
        position: relative;
        bottom: -$font-size-base * 0.1;
      }

      .d-flex {
        span{
          white-space: nowrap;

          &:first-of-type{
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.ml-auto{
            padding-left: 0.5rem;
            color: $ui-gray;
          }
        }
      }
    }

    .dropdown-divider{
      margin:  $grid-gutter-width-base/4 0;
    }

  }
}

.scrolled-up{
  .bell-notifications--unread:before{
    top:-2px;
  }
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0.0;
  }
  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0.0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }
  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
}
