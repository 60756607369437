.gma-backdrop {
    div {
        position: fixed;
        z-index: 1050;
        @include backdrop-color();
    }
}

.gma-popover-body {
    overflow: hidden;
}

.gma-backdrop {
    z-index: 1050;
}

.gma-full-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    @include backdrop-color();

    .gma-tour-no-element {
        margin: 70px auto 0 auto;
        width: 10px;
        height: 10px;
    }
}