.widget{
  &-chart{
    &__btn-group{
      padding: 20px;

      .btn{
        cursor: pointer;
      }
    }
  }
}