.#{$prefix}-btn-group {
    background-color: $btn-bgcolor;
    height: $btn-size+2;
    border: 1px solid $btn-border;
}


.#{$prefix}-btn {
    float: left;
    height: $btn-size;
    width: $btn-size;
    font-size: $btn-font-size;
    color: $dark-color;
    background: none;
    border: 0;
    cursor: pointer;
    outline: 0;
    transition: background-color 150ms;

    &:hover {
        background-color: $btn-bgcolor-hover;
    }

    &-dropdown-icon {
        position: absolute;
        bottom: 0;
        margin-left: -4px;
    }

    &-spacing {
        content: " ";
        float: left;
        border-right: 1px solid $btn-border;
        width: 0px;
        height: $btn-size;
        margin: 0 5px;
    }

    &-container {
        position: relative;
        float: left;
        padding-left: 5px;

        &:focus &-dropdown {
            display: block;
        }

        &-dropdown {
            display: none;
            position: absolute;
            background: $dropdown-bg;
            margin: $btn-size 0 0 0;
            padding: 1px 5px;
            border: 1px solid $btn-border;
    
            li {
                list-style: none;
                display: block;
                padding: 5px 10px;
                white-space: nowrap;
                cursor: pointer;
            }

            li:hover {
                background: $btn-bgcolor-hover;
            }
        }
    }
}