ul.jqtree-tree {
  display: flex;
  flex-direction: column;

  padding-left: 0;
  margin-bottom: 0;

}

  ul.jqtree_common {
    list-style: none outside;
    margin-left: $list-group-item-padding-x;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    display: block;
  }

  li.jqtree-closed > ul.jqtree_common {
    display: none;
  }

  li.jqtree_common {
    clear: both;
    list-style-type: none;
  }

  .jqtree-toggler {
    border-bottom: none;
    color: #333;
    text-decoration: none;
    vertical-align: middle;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    &.jqtree-closed {
      background-position: 0 0;
    }

    &.jqtree-toggler-left {
      margin-right: 0.5em;
    }

    &.jqtree-toggler-right {
      margin-left: 0.5em;
    }
  }

  .jqtree-element {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: move;
    padding: $list-group-item-padding-y $list-group-item-padding-y $list-group-item-padding-y 0;

    margin-bottom: $list-group-item-padding-y;
    background-color: $list-group-bg;
    border: $list-group-border-width solid $list-group-border-color;
  }

  .jqtree-container {
    background-color: $list-group-bg;
    border: $list-group-border-width solid $list-group-border-color;
  }

  .jqtree-selector {
    &:hover {
      border-color: $ui-text-color;
    }
  }
  .jqtree-active {
    box-shadow: 0px 0px 0px 2px $ui-blue;
    transition: all .3s;
    border-color: $ui-blue;
  }
  .jqtree-empty-menu {
    border: 2px dashed $list-group-bg;
  }

  .jqtree-slot {
    flex: 1 0 calc(100%/5);
    box-sizing: border-box;
  }

  .jqtree-title {
    color: $ui-text-color;
    vertical-align: middle;
    margin-left: 1.5em;

    &.jqtree-title-folder {
      margin-left: 0;
    }
    &.dragula-main-item {
      > .jqtree-icon {
        margin-right: 10px;
        margin-left: 0 !important;
      }
    }
  }

  li.jqtree-folder {
    margin-bottom: 4px;

    &.jqtree-closed {
      margin-bottom: 1px;
    }
  }

  li.jqtree-ghost {
    position: relative;
    z-index: 10;
    margin-top: -10px;
    padding: 15px 0;

    /* todo: add classes to span? */
    span {
      display: block;
    }

    span.jqtree-circle {
      display: none;
    }

    span.jqtree-line {
      border: 2px dashed $gray-lighter;
      height: 45px;

      padding: 0;
      width: 100%;
    }

    &.jqtree-inside {
      margin-left: 48px;
    }
  }

  span.jqtree-border {
    position: absolute;
    display: block;
    left: -3px;
    top: 0;
    border: solid 3px $gray-lighter;
    margin: 0;
    box-sizing: content-box;
  }

  .jqtree-selector .jqtree-folder + .dragula-slot-empty {
    display: none;
  }

//   li.jqtree-selected > .jqtree-element,
//   li.jqtree-selected > .jqtree-element:hover {
// //
//   }

//   .jqtree-moving > .jqtree-element .jqtree-title {
//     //outline: dashed 1px #0000ff;
//   }
// }

ul.jqtree-tree.jqtree-rtl {
  direction: rtl;

  ul.jqtree_common {
    margin-left: 0;
    margin-right: 12px;
  }

  .jqtree-toggler {
    margin-left: 0.5em;
    margin-right: 0;
  }

  .jqtree-title {
    margin-left: 0;
    margin-right: 1.5em;

    &.jqtree-title-folder {
      margin-right: 0;
    }
  }

  li.jqtree-ghost {
    margin-right: 0;
    margin-left: 10px;

    span.jqtree-circle {
      right: -6px;
    }

    span.jqtree-line {
      right: 2px;
    }

    &.jqtree-inside {
      margin-left: 0;
      margin-right: 48px;
    }
  }

  span.jqtree-border {
    right: -2px;
  }
}
.jqtree-icon{
  font-size: 20px;
  cursor: pointer;
  line-height: 12px;
  position: relative;
  bottom: -2px;
  color: $ui-text-color;
  transition: $transition-base;
  margin-left: 10px !important;

  &:hover{
    color: $ui-link-color;
  }
}
.jqtree-title{
 outline: none !important;
}
span.jqtree-dragging {
  color: #fff;
  background: #000;
  opacity: 0.6;
  cursor: pointer;
  padding: 2px 8px;
}

