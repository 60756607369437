/**
* header
*/

body{
  min-height: 100vh;
  background: $ui-body-gradient-bg ;
}

#content-wrapper{
  transition: all .5s;
  margin-left: $left-menu-width;
  margin-right: 0;
  border-top: $top-menu-height solid $ui-body-bg;
  background: $ui-content-wrapper-bg;
  padding: $grid-gutter-width-base $grid-gutter-width-base*2 ;
  min-height: 100vh;
  position: relative;

  &.collapsed-nav-left{
    margin-left: $left-menu-width-collapsed;
  }

  .wrapper__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    bottom: 0;
    background-color: $left-menu-overlay-bg;
    z-index: 1002;
    opacity: 1;
    transition: opacity .5s;

    &.hidden {
      left: -3999px;
      right: 3999px;
      opacity: 0;

    }
  }
}