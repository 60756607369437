@mixin table-border($type, $color) {
    border: 1px $type $color;
}

@mixin table-border-style($type, $color) {
    @include table-border($type, $color);
    thead, tbody {
        tr {
            th, td {
                @include table-border($type, $color);
            }
        }
    }
}

.#{$prefix}-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    th, td {
        padding: 5px;

        &:empty:after {
            content: '.';
            visibility: hidden;
            speak: none;
        }
    }

    &-preview {
        padding: 10px;
        @include table-border-style(solid, $btn-border);
        border-top: none;
    }

    &-editable {
        form, input {
            width: 100%;
        }
        @include table-border-style(dashed, $table-border-edit-color);
    }

    &-bordered {
        @include table-border-style(solid, $table-border-color);
    }

    &-striped {
        tbody tr:nth-of-type(odd) {
            background-color: $table-striped-color;
        }
    }

    &-active {
        color: $table-active-color;
        background-color: $table-active-bgcolor;
    }
}