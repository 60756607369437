.dropdown-menu-right:not(.table-actions) {
  left: auto !important;
  right: 0 !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.dropdown-item {
  white-space: normal !important;
  word-break: break-word !important;
}
.show > .dropdown-menu {
  z-index: 1100;
}

.dropdown-menu.show {
  transform: translate3d(5px, 35px, 0px) !important;
}
