/*
 * COLORS
 */

@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400&subset=latin-ext');

$box-shadow:                $ui-box-shadow;


// mdi-font config
$mdi-font-path:        "/static/cms/fonts";
$mdi-font-size-base:   24px;
$mdi-css-prefix:       ico;
$mdi-version:          "2.0.499";

$mdi-font-name:        "Material Design Icons";

[class^="#{$mdi-css-prefix}-"],
[class*=" #{$mdi-css-prefix}-"] {
  display: inline-block;
  font: normal normal normal #{$mdi-font-size-base}/1 '#{$mdi-font-name}'; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Body & page structure

$left-menu-width:           220px !default;
$left-menu-mobile-width:    220px !default;
$left-menu-width-collapsed: 62px !default;
$top-menu-height:           56px !default;

$btn-icon-color: $ui-btn-icon-color !default;
$btn-icon-background: transparent !default;
$btn-icon-border: transparent !default;


$input-box-shadow : 0 0 0 ;



$ui-unlogged-bg : linear-gradient(135deg, #80b6a3 0, #80a6b3 100%);