@mixin amf-li() {
  li {
    margin: 0;
    padding: 5px 5px 5px 15px;
  }

  li:hover {
    background: #efefef;
  }
}

.amf {

  .hideable {
    background: $ui-card-real-bg;
    position: absolute;
    top: 35px;
    z-index: 1000;
  }

  .field-list {
    border: 1px solid $input-border-color;
    height: 250px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    input[type="checkbox"] {
      display: none;
    }

    li {
      margin: 0;
      padding: 5px;
    }

    label {
      display: block;
      margin: 0;
      cursor: pointer;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      @include amf-li();
    }

    &.select-list {

      input:checked + span {
        color :red;
      }
    }

    &.selected-list {

      @include amf-li();
    }
  }

  .selectable-list .option-checked {
    background: darken($ui-state-light-bg, 7%);
  }

  .list-icon {
    font-size: 24px;
  }
}