.dashboard {
  margin: 0 -11px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  .visible-dashboard-edited {
    display: none;
  }

  &--edited {
    .visible-dashboard-edited {
      display: inline-block;
    }
    .hidden-dashboard-edited {
      display: none;
    }
    .dashboard-widget {
      box-shadow: 0 2px 5px 0 rgba(64, 117, 198, 0.5),
      0 2px 10px 0 rgba(64, 117, 198, 0.5);

      cursor: move;

      &:hover {
        box-shadow: 0 2px 5px 0 rgba(64, 117, 198, 0.85),
        0 2px 10px 0 rgba(64, 117, 198, 0.85);
      }

      &--add-widget {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        @include justify-content(center);
        cursor: pointer;
        padding: 10px 0;
        position: relative;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          content: '';
          pointer-events: none;
          background: rgba(127, 165, 179, 0.075);
        }
        &:hover {
          &:before {
            background: rgba(127, 165, 179, 0.27);
          }
        }

        i {
          font-size: 2em;
          display: block;
        }
      }
    }
  }

  &__item {
    padding: 0 11px;
    margin-bottom: 22px;
    transition: all .5s;

    &--1 {
      width: 25%;

      @include media-breakpoint-down('lg') {
        width: 50%;
      }
      @include media-breakpoint-down('sm') {
        width: 100%;
      }

    }
    &--2 {
      width: 50%;
      @include media-breakpoint-down('lg') {
        width: 100%;
      }
    }
    &--3 {
      width: 75%;
      @include media-breakpoint-down('lg') {
        width: 100%;
      }
    }
    &--add-widget,
    &--4 {
      width: 100%;
    }
  }

  &-widget {
    background: $ui-card-bg;
    border-radius: 0px;
    box-shadow: $box-shadow;
    border-radius: 0.15rem;
    min-height: 100%;
    transition: all .25s;

    @include flexbox();
    @include flex-direction(column);

    &__header {
      padding: 12px 17px;
      @include flexbox();
      @include align-items(center);
      @include flex-direction(row);
      @include justify-content(space-between);

      > h5 {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        color: $ui-heading-color;
      }
      > div {
        @include flexbox();
        @include flex-direction(row);
        margin: -10px -10px -10px 5px;
      }
    }

    &__body {
      padding: 12px 17px;

      &--table {
        padding: 0;

        thead th {
          border-bottom-width: 1px;
        }
        th,
        td {
          padding: 7px 5px;
          border-color: $ui-card-separator;
          vertical-align: middle;

          &.has-btn-icon {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        th {
          color: $ui-heading-color;
          border-top-width: 0;
          font-size: 13px;
          font-weight: 500;
        }

        td:first-of-type,
        th:first-of-type {
          padding-left: 17px;
        }
        td:last-of-type,
        th:last-of-type {
          padding-right: 17px;
        }

      }

      &--kpi{
        padding: 0px 17px;
        margin-bottom: -5px;

        .card{
          color: white;
          text-align: center;
          text-align: center;
          height: calc(100% - 22px);
          position: relative;

          &:after{
            content: '';
            position: absolute;
            bottom: 0;
            top:0;
            left: 0;
            right: 0;
            background: linear-gradient(135deg, #ffffff50 0, #ffffff00 100%); //#f6f8fa  ;
          }


          .card-block{
            padding: 0.5rem;
          }
          small{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
          h2,h3,h4,h5{
            margin: 4px 0;
            color: white;
          }

          .badge-success{
            color:#4ef190;
          }
        }

      }
    }
  }



  [data-identifier=widget-todo] {
    .input-group {
      .form-control {
        border-right-width: 0;

        &:focus + .input-group-btn {
          .btn-icon {
            border-color: $input-border-focus
          }
        }
      }
      .btn-icon {
        border-color: $input-border-color;
        border-left-width: 0;
        border-top-right-radius: 0.1rem;
        border-bottom-right-radius: 0.1rem;
      }
    }
    [data-action="dashboard-todo-editName"]{
      padding-right: 22px;
      position: relative;
      &:after{
        font: normal normal normal 48px/1 "Material Design Icons";
        content: mdi('pencil');
        right: 4px;
        font-size: 16px;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        color: $gray-light;
        opacity: 0;
        transition: $transition-base;
      }

      &:hover{
        &:after{
          opacity: 1;
        }
      }
    }
    .openEditForm {
      position: relative;

      form {
        position: absolute;
        left: 5px;
        right: 3px;
        top: 2px;
        bottom: 2px;
        width: auto;

        textarea{
          resize: none;
          padding:5px 10px 2px;
          line-height: 1.5;
          font-size: 0.9rem;
        }

        .btn-icon i{
          font-size: 18px;
        }
      }
    }
  }

}