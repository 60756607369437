.card {
  box-shadow: $box-shadow;

  &.card-tabsable {
    .card-header {
      border-bottom-color: transparent;
    }

    .card-block {
      .card-header-tabs {
        padding-left: $card-spacer-x/2;
        padding-right: $card-spacer-x/2;
        margin: 0;
        border-bottom: 1px solid $card-border-color;
      }
    }
  }

  &.card-collasable {

    .card-header {
      cursor: pointer;
      .card-close {
        margin: -7px 0;
      }
    }

    .card-block {
      padding: 0;

      &-content {
        padding: $card-spacer-y $card-spacer-x;
      }

      .form-group {
        border-bottom: $card-border-width solid $card-border-color;
        margin: 0;
        padding: $card-spacer-x/2;
      }
      .col-form-label {
        &.additional-desc {
          padding-top: 0;
          padding-bottom: 0;
          @include flexbox;
          @include flex-direction(row);
          @include justify-content(space-between);
          > * {
            @include align-self(center);
          }
          .ico-plus-circle-outline,
          .ico-help-circle-outline{
            font-size: 22px;
            margin-right: -($grid-gutter-width-base/2)-5 ;

          }
          span {
            word-break: break-word;
          }
        }
      }
      label {
        .ico-asterisk{
          font-size: 10px;
          padding-left: 5px;
          top: -5px;
          position: relative;
        }
      }

    }
  }

  &-block-actions {
    padding: $card-spacer-y 0;

    & + .card-block {
      padding-top: 0;
    }

  }

  &-block-filters {
    border-bottom: 1px solid $ui-card-separator;
    border-top: 1px solid $ui-card-separator;

    > form {
      margin: $card-spacer-y $card-spacer-x;
      font-size: 0.8rem;
      label {
        margin-bottom: 0.25rem;
      }
      .form-group {
        margin-bottom: 0.5rem;
        &--has-value{
          padding-right: 30px;
          .input-group-btn.position-absolute{
            position: absolute !important;
            display: block;
            right: -30px;
            top: 2px;
          }
        }
      }
      .form-control {
        padding: 0.35rem 0.5rem;
      }
      .input-group-addon {
        padding: 0.25rem 0.5rem;
        width: auto;
      }
      .bootstrap-select {
        box-shadow: 0 0 0;
        padding: 0;

        .filter-option{
        margin-bottom: -3px;
        padding-top: 3px;
      }
      }
      .btn {
        padding-top: 0.23rem;
        padding-bottom: 0.23rem;
      }
    }
    & + .card-block {
      padding-top: 0;
    }

    &--list {
      padding: 0 $card-spacer-x $card-spacer-y;

      button {
        margin-top: 0 !important;
        margin-bottom: 4px !important;
      }
    }
  }

  &-block-default {
    padding: $card-spacer-x;
  }

  &-footer{

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: calc(#{$card-spacer-y} - 5px);


    &:before{
      content: '';
      margin-left: auto;
    }
    
    .btn {
      margin-top: 5px;
    }

    .btn + .btn{
      margin-left: 5px;
    }

  }

}