.fixed-table {
  /* magic */
  width: 100%;
  table-layout: fixed;

  /*not really necessary, removes extra white space */
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;

  td {
    padding:0 !important;
    border:0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
