/***
*  Tytul: nestedList
*  Opis:  Mixin dla list z paddingiem po lewej stronie, zwiększającym się o daną warość wraz z poziomem zagnieżdżenia.
*  Autor: M. Garas
*  Data: 13.07.2017
* Legenda:
*      $list - zmienna dla klasy listy głównej,
*      $list-item - zmienna dla elementów listy,
*      $sub-list - klasa dla wszystkich sub-list,
*      $sub-list-item - zmienna dla elementów sublist,
*      $i - 1 liczba niezmienna,
*
*  Sposób użycia
*    .table-of-content{
*     @include nested-list (".content-list", ".content-list__item", ".content-list__sub", ".content-list__sub__item");
*    }

*
***/

@mixin nestedList($list, $list-item, $sub-list, $sub-list-item, $i:1){

  $sublists: 5 !default; //zdefiniowana liczba poziomów zagnieżdzeń w menu

  @for $i from 1 through $sublists {
    #{$sub-list} {
      padding-left: 5px * $i;
      list-style: none;

      #{$sub-list-item} {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  #{$list} {
    list-style: none;
  }
  #{$list-item} {
    padding: 5px 0;
  }


}