// Badges

@mixin badge-variant($color) {
  color: $color;
  background-color: rgba($color, 0.1);

  @include hover-focus {
    background-color: rgba($color, 0.1);
  }
}

@mixin badge-variant-active($color) {
  background-color: $color;
  color: $white;
  cursor: pointer;

  @include hover-focus {
    background-color: lighten($color, 10%);
  }

}
