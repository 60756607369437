.promotions{
  &-condition-row{

    &:last-of-type:first-of-type{
      .btn-icon--defeat{
        visibility: hidden;
      }
    }
    &:last-of-type{
      .btn-icon--default{
        visibility: visible !important;
      }
    }


    .btn-icon--defeat{
      opacity: 0;
    }
    .btn-icon--default{
      visibility: hidden;
    }
    .btn-icon--default,
    .btn-icon--defeat{
      height: 26px;
      width: 26px;
      padding: 0;
      margin: 3px 0;
      i{
        line-height: 26px;
        font-size: 20px;
      }
    }
    &:hover{
      .btn-icon--defeat{
        opacity: 1;
      }
    }
  }
  &-set{
    margin-left:  -$card-spacer-x ;
    margin-right:  -$card-spacer-x;
    margin-bottom:  $card-spacer-x/2;
    border-bottom: $card-border-width solid $card-border-color;
    padding: 0 $card-spacer-x/2;

    &__label{
      width:calc(100% + (2 * 44px));
      margin-left:  -$grid-gutter-width-base;
      margin-top:  -$grid-gutter-width-base/2 ;
      padding-left:  $card-spacer-x;
      padding-top: $grid-gutter-width-base/2;
      background: $ui-state-light-bg;
      border-top: $card-border-width solid $card-border-color;
      border-right: $card-border-width solid $card-border-color;
    }

    &__variables-quantity{
      margin-left:  -$grid-gutter-width-base;
      margin-right:  -$grid-gutter-width-base;
      padding-left:  $card-spacer-x;
      border-top: $card-border-width solid $card-border-color;

      .form-group{
        margin-left: 0;
        label{
          padding-left: 0;
        }
      }
    }

    &__exceptions{
      position: relative;
      padding: $card-spacer-x/4 0;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: -2px;
        right: -2px;
        margin-left:  -$card-spacer-x ;
        margin-right:  -$card-spacer-x;
        border-bottom: $card-border-width solid $card-border-color;
      }
    }
  }
}