.card-tiles {

    &.per-row-3 .select-tile:nth-child(3n+1) {
        clear: left;
    }

    &.per-row-4 .select-tile:nth-child(4n+1) {
        clear: left;
    }

    .select-tile {
        display: block;
        float: left;
        width: 150px;
        border: 1px solid $ui-gray;

        .tile-img {
            text-align: center;
            height: 160px;
        }

        .tile-txt {
            height: 70px;
        }

        &.ui-selecting, &.ui-selected{
            position: relative;
            &:after {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                content: "";
                background: rgba(125, 125, 255, 0.5);
            }
        }
    }   
}
    