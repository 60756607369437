.unlogged-page {
  background: $ui-unlogged-bg;

  padding: $grid-gutter-width-base $grid-gutter-width-base*2;

  min-height: 100vh;

  .bg-light{
    background: $white !important;
  }

  &__wrapper {
    @include media-breakpoint-up('sm') {
      padding-top: 10vh;
    }
    width: 400px;
    margin-left: auto;
    margin-right: auto;

    &--text-page{
      width: 600px;
    }

    max-width: 100%;
    .col-form-label{
      padding-bottom: calc(0.25rem);
      font-size: 0.8rem;

      .ico-asterisk{
        font-size: 8px;
        top: -3px;
        position: relative;
        padding-left: 4px;
      }

    }

    .form-group{
      .col-12{
        flex: 0 0 100%;
        max-width: 100%;


        .form-control{
          padding-left: 0;
          border-radius:0 ;
          border-width: 0 0 1px 0 ;
          box-shadow: 0 0 0;
        }
      }

      .col-12.text-right{
        padding-top: 1rem;
        text-align: left !important;
      }
    }

  }

  &__logo {
    width: 200px;
    margin-bottom: 2em;

    @include media-breakpoint-down('xs') {
     margin-left:auto ;
     margin-right:auto ;
    }

    svg {
      width: 100%;
      height: auto;

      * {
        fill: $ui-white;
      }
    }

  }
  &__link{
    @include media-breakpoint-up('sm') {
      text-align: right;
      margin-top: -51px;
    }

    a{
      padding-left: 0;
      padding-right: 0;
      position: relative;
      z-index: 10;
    }
  }

  &__footer{
    @include flexbox();

    .btn-link{
      color: $ui-white;
    }
  }

  &--loading{
    .card{
      position: relative;
      overflow: hidden;

      &:after{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border-radius:2px ;
        background-color: rgba($white, 0.4);
        content: '';
        z-index: 1000;
        border-top: 4px solid $gray-lighter ;
      }
      &:before{
        display: block;
        position: absolute;
        content: "";
        left: -200px;
        width: 200px;
        height: 4px;
        background-color: $ui-purple;
        animation: unlogged-loading 1s linear infinite;
        z-index: 1001;
      }
    }
  }
}

@keyframes unlogged-loading {
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
}