.media {

  &-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    padding: $grid-gutter-width-base/4;
  }

  &-box-flex {
    max-height: calc(100vh - 190px);
    padding-right: 0;

  }

  &-item {
    position: relative;
    //max-width: 130px;
    margin: $grid-gutter-width-base/4;

    $itemInLine: (
            8:2200px,
            7:1500px,
            6:1284px,
            5:810px,
            4:648px,
            3:493px,
            2:334px,
    );
    @each $key, $value in $itemInLine {

      @media (max-width: #{$value}) {
        width: calc((100% / #{$key}) - (#{$grid-gutter-width-base} / 2));
      }

    }

    &:not(:empty) {
      text-align: center;
      border: 1px solid $card-border-color;
      word-wrap: break-word;
      cursor: pointer;
      padding: 0px;
      transition: $transition-base;
      &:before {
        display: block;
        content: '';
        padding-top: 100%;
        position: relative;
      }
    }

    &.highlight {
      background: #ffa500;
    }

    &.active {
      border-color: $brand-primary;
      box-shadow: inset 0 0 0 2px $brand-primary;
    }

    img {
      display: block;
      position: absolute;
      max-width: 100%;
      top: 0;
      left: 0;
      right: 0;
    }

    .form__checkbox {
      position: absolute;
      top: 5px;
      z-index: 100001;
      right: 5px;

      label {
        display: none;
      }

      input:checked + label {
        display: block;
      }
    }

    &:hover {
      border-color: $ui-brand-info;
    }

    &__text {
      display: none;
      padding: 5px;
      //white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 44px;
      font-size: 80%;

      &--dir {
        display: block;
      }
    }

    &-description {
      height: calc(100vh - 190px);
      display: flex;
      flex-direction: column;
      border-left: $modal-header-border-width solid $modal-header-border-color;

      // align-content: space-between;
      [data-bind] {
        word-break: break-all;
      }

      .i-large {
        font-size: 150%;
      }

      > div:nth-child(1) {
        flex: 1;
      }

      div.bottom {
        min-height: 155px;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        align-self: flex-end;
        border: 4px dashed $card-border-color;
        .dropzone {
          text-align: center;
          border: 0 !important;
          background-color: inherit !important;
          .dz-error-message {
            top: 20px;
            left: -30px;
            width: 180px;
            overflow: hidden;
            word-break: break-all;
            height: auto;
            &::after {
              display: none !important;
            }
          }
        }
      }
    }
  }

  &-mimetype {
    &-link {
      display: flex;

      img{
        margin: 0 !important;
      }

      * {
        align-self: center !important;
      }

      >div{
        position: relative;
      }

      div {
        border: 1px solid $ui-state-light-bg;
        padding: 2px;
        border-radius: $border-radius;
        display: block;
        width: 64px;
        height: 64px;
        display: block;
        background: $ui-card-real-bg;
        margin-right: 10px;
        text-align: center;
      }

      &:hover {
        div {
          border-color: $ui-input-border-color;
        }
      }
    }
    &-icon {
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      display: block;
      color: $ui-link-color;
      font-size: 32px !important;
    }
  }
}

// @include media-breakpoint-up(sm) {
//   .media-item {
//     width: calc( 33.333% - 3px);
//   }
// }

// @include media-breakpoint-up(lg) {
//   .media-item {
//     width: calc( 25% - 3px);
//   }
// }