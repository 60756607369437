.close {
  float: right;
  font-size: $close-font-size;
  font-weight: $close-font-weight;
  line-height: 1;
  color: $close-color;
  opacity: .75;
  padding: 0 5px;
  span{
    display:block;
    height: 24px;
    width: 24px;
    line-height: 20px;
  }

  font-family: 'Roboto', sans-serif !important;


  @include hover-focus {
    color: $close-color;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// scss-lint:disable QualifyingElement
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
// scss-lint:enable QualifyingElement
