@mixin ul-style() {
    display: block;
    margin: 1px 0 ;
    padding: 0;
}

@mixin li-style() {
    list-style: none;
    margin-bottom: 2px;
    padding-right: 4px;
    word-break: break-all;
    overflow: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        color: $gma-text-select;
        outline: 1px solid $gma-lines-color;
    }

    div:nth-child(1) {
        cursor: pointer;
        padding: $gma-list-item-padding-top 0 10px 10px;
    }
}

.gma {
    
    &-list-lesson {
        @include ul-style();

        li {
            @include li-style();

            div:nth-child(1) {
                font-weight: bold;
            }

            div:nth-child(2) {
                padding-left: 5px;
                margin: -#{ $gma-list-item-padding-top / 2 } 0 0 0;
                display: flex;
            }
        }

        $items: new break done;
        $items-col: $gma-list-item-new $gma-list-item-break $gma-list-item-done;
        @each $item in $items {
            $i: index($items, $item);
            .item-#{$item} {
                border-left: 5px solid nth($items-col, $i);
            }
        }
    }

    &-list-steps {
        @include ul-style();

        li {
            @include li-style();
            flex-wrap: wrap;

            div:nth-child(1) {
                flex: 1;
                flex-grow: 1000;
                font-weight: bold;
            }

            div:nth-child(2) {
                flex: 1;
                padding-left: 5px;
                margin: -#{ $gma-list-item-padding-top / 2 } 0 0 0;
                display: flex;
            }

            div:nth-child(3) {
                width: 100%;
            }
        }
    }

    &-list-title {
        word-break: break-all;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 50px;

        div:nth-child(1) {
            align-self: stretch;

            button {
                padding: 0 5px;
                height: 100%;
            }
        }

        div:nth-child(2) {
            flex-grow: 1;
            font-weight: bold;
            font-size: 105%;
            padding: 0 5px;
        }

        div:nth-child(3) {
            padding-right: 5px;
        }
    }

    &-step-description {
        padding: 5px 15px;
        @include font-darker();
    }

}
