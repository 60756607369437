

//style dla menu mobilenego
@include media-breakpoint-down(md){
  #content-wrapper [for="menu-aside__unchecked--submenu"]{
    display: none !important;
  }

  .menu-aside__submenu__list{
    width: $left-menu-mobile-width !important;
  }


  .navbar-right {
    display: block;
    position: absolute;
    right: 0;
    top: 10px;

    > li {
      display: inline-block;
      padding-right: 20px;
    }
  }


  //menu mobilne

  #navbar-left {
    position: absolute;
    left: -$left-menu-mobile-width;


    .menu-aside {
      height: 100%;
      display: block;
      padding-bottom: 30px;
      background: $ui-left-menu-gradient-bg;
      z-index: 9999;
      overflow-y: auto;
      overflow-x: hidden;

      &__unchecked--submenu {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

    }


    .menu-aside__label--unchecked {
      float: left;
      padding-left: 10px;
    }

    .menu-aside__submenu__list {
      left: -300px;
      background-color: $ui-left-menu-sub-bg;;
      display: block;
      overflow-y: auto;
      overflow-x: hidden;

    }
    .menu-aside__submenu__header{
      top: 20px;
    }

    .menu-aside__submenu {
      z-index: 999;
      .nav-link, .nav-head {
        color: $ui-left-menu-sub-color;
      }

      &__header {
        color: $ui-left-menu-sub-color;
      }
    }
    .menu-aside__check--submenu:checked ~ .menu-aside__submenu__list{
      padding-top:60px;
    }
  }



  #navbar-top, #content-wrapper {
    margin-left: 0 !important;
  }
  #menu-aside__check--main:checked {
    & ~ #navbar-top {
      margin-left: $left-menu-mobile-width !important;
      margin-right: -$left-menu-mobile-width !important;
    }

    & ~ #content-wrapper {
      margin-left: $left-menu-mobile-width !important;
      margin-right: -$left-menu-mobile-width !important;
    }

    & ~ #navbar-left {
      left: 0;
      width: $left-menu-mobile-width !important;
      .menu-aside > .menu-aside__item {

        > .nav-link {
          width: auto !important;
          overflow: visible;
          color: #56707c !important;

          &:hover {
            background-color: transparent !important;

            i {
              background-color: transparent !important;
            }
          }

          span {
            opacity: 1;
            background-color: transparent !important;;
          }
        }
        .nav-head {
          width: auto !important;
          overflow: visible;

          &:hover {
            background-color: transparent !important;
            i {
              background-color: transparent !important;
            }
          }
          span {
            opacity: 1;
            background-color: transparent;
            left: 50px;
          }

        }
      }
    }

    & ~ #content-wrapper .menu-aside__check--mobile{
      left: 0 !important;
      right: 0 !important;
      top: -30px;
      bottom: 0;
      background-color: $left-menu-overlay-bg;
      z-index: 1002;
      opacity: 1 !important;
      transition: opacity .5s;


    }
  }
  .menu-aside__check--main:checked ~ #navbar-left .menu-aside__check--submenu:checked {

    ~ .menu-aside__submenu__list {
      left: 0 !important;
      min-width: $left-menu-mobile-width;
      width: $left-menu-mobile-width;

    }
  }

  .menu-aside__check--mobile.hidden {
    position: absolute;
    left: -3999px;
    right: 3999px;
    opacity: 0;

  }

  .menu-aside__submenu {
    //todo
    //z-index: 999;
  }
  .menu-aside__check--main:checked ~ #navbar-left .nav-head, .menu-aside__check--main:checked ~ #navbar-left .nav-link {
    white-space: nowrap;
    width: auto;
    display: block;
  }
  .menu-aside__check--main:checked ~ #navbar-left .nav-head, .menu-aside__check--main ~ #navbar-left .nav-link {
    white-space: nowrap;
    width: auto;
    display: block;
    margin-right: 10px;
  }
}
