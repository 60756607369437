/**
* ToDo
*/

@import "variables";

@import "framework/bootstrap";
@import "partials/fonts";

/**
* Icons
*/

//@import "lib/materialdesignicons/materialdesignicons";
@import "./../node_modules/@mdi/font/scss/materialdesignicons";
/**
* LIBS
*/
@import "lib/bootstrap-select/bootstrap-select";
// @import './../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css';
@import "partials/button-select";
$gray-200: $gray-lightest;
$gray-600: $gray;
@import './../node_modules/tempusdominus-bootstrap-4/src/sass/_tempusdominus-bootstrap-4';

@import "lib/flags/flag-icon";

@import "lib/bootstrap-colorpicker/colorpicker";
@import "lib/chartist-js/chartist.scss";
// @import "lib/trumbowyg";
@import "lib/lity.scss";
@import "lib/gma/main.scss";

$light-color: $ui-state-light-bg;
$dark-color: $ui-text-color;
@import "./../node_modules/trumbowyg/dist/ui/sass/trumbowyg";
@import "./../node_modules/trumbowyg/dist/plugins/colors/ui/sass/trumbowyg.colors";

@import "./../node_modules/cropperjs/src/index.scss";

@import "lib/table-creator/main";

@import "lib/mCustomScrollbar/jquery.mCustomScrollbar.scss";

@import "lib/jquery.autocomplete";
@import "lib/dragula.scss";
@import "lib/dropzone.scss";
@import "lib/jqtree";

/**
* Mixins
*/
@import "partials/mixins/material-click-animation";
@import "partials/mixins/table-density";
@import "partials/mixins/list";
@import "partials/mixins/flexbox";

/**
* Customes
*/

@import "partials/autocomplete-multiselect-field";
@import "partials/blink";
@import "partials/button-circle";
@import "partials/button-icon";
@import "partials/card-in-modal";
@import "partials/custome-forms";
@import "partials/dropdown";
@import "partials/fixed-table";
@import "partials/globals";
@import "partials/gma";
@import "partials/google-maps";
@import "partials/homepage-section";
@import "partials/input-group";
@import "partials/list-tree";
@import "partials/lists";
@import "partials/loading-layer";
@import "partials/mCustomScrollbar-theme";
@import "partials/media-field";
@import "partials/media";
@import "partials/modal-media";
@import "partials/nav-header-menu";
@import "partials/screen-visibility";
@import "partials/table";
@import "partials/table-tiles";
@import "partials/timeline";
@import "partials/upload-file";
@import "partials/widget";
@import "partials/ui-panel";
@import "partials/promotions";
@import "partials/trumbowyg";
@import "partials/two-d-form";



/**
* Page structure
*/
@import "partials/navbar-left";
@import "partials/navbar-left-mobile";
@import "partials/navbar-top";
@import "partials/content-wrapper";
@import "partials/card";
@import "partials/unlogged-page";


/**
* Pages
*/
@import "partials/dashboard";


/**
* mobile
*/
@import "partials/mobile-sizing";


/**
* Advanced Filters
*/
@import "partials/_advanced-filters";