//
// Contextual backgrounds
//

.bg-faded {
  background-color: darken($body-bg, 3%);
}

@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-success', $ui-state-success-bg);

@include bg-variant('.bg-info', $brand-info);

@include bg-variant('.bg-warning', $brand-warning);

@include bg-variant('.bg-danger', $ui-state-danger-bg);

@include bg-variant('.bg-inverse', $brand-inverse);

@include bg-variant('.bg-light', $ui-state-light-bg);
