$prefix: 'tabs';

$table-border-color: black;
$table-border-edit-color: $input-border-color;
$table-striped-color: #f2f2f2;

$table-active-color: inherit;
$table-active-bgcolor: $ui-state-success-bg;

$btn-size: 38px;
$btn-font-size: 17px;
$btn-bgcolor: $light-color;
$btn-bgcolor-hover: $light-color;
$btn-border: darken($light-color, 7%);