//
// Base styles
//

.alert {
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: 0 solid transparent;
  border-left-width:4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  @include border-radius($alert-border-radius);
  word-break: break-all;
  overflow-y: auto;
  max-height: 300px;  
  width: calc(100% - 220px - 88px);
  margin-right: 44px;
  @include media-breakpoint-down(md){
    width: calc(100% - 0px - 88px);
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  // Adjust close link position
  .close {
    position: relative;
    top: -$alert-padding-y;
    right: -$alert-padding-x;
    padding: $alert-padding-y $alert-padding-x;
    //color: inherit;
    //font: normal normal normal 36px/1 "Material Design Icons";
    //content: mdi('loading');
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @include alert-variant($ui-state-success-bg, $ui-brand-success, $ui-text-color);
}
.alert-info {
  @include alert-variant($ui-state-info-bg, $ui-brand-info, $ui-text-color);
}
.alert-danger,
.alert-warning {
  @include alert-variant($ui-state-warning-bg, $ui-brand-warning, $ui-text-color);
}