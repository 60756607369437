.only-screen-invisible {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: -1;
  > * {
    opacity: 0;
    transition: $transition-base;
  }
}

.invisible-on-screen {
  .only-screen-invisible {
    z-index: 10000;

    > * {
      opacity: 1;
    }
  }
}