/**
*mixin test
 */

@mixin tableDensity($name, $fontSizeMultiplier, $sizeMultiplier) {

  #{$name} {
    font-size:$fontSizeMultiplier*$font-size-base;

    th {
      font-size: $fontSizeMultiplier*16px;
      span, label {
        padding: $sizeMultiplier*10px $sizeMultiplier*5px;
        display: block;

        &.btn-group{
          padding-top:0;
          padding-bottom:0;
        }

      }
    }
    tr {
      td {
        padding:  (($sizeMultiplier*3px)-2) $sizeMultiplier*5px;

        .ico-drag-vertical,
        .form__checkbox{
          padding: $sizeMultiplier*1px 0;
        }

        height: $sizeMultiplier*45px;

        img.img-fluid{
          max-width: 100%; //$sizeMultiplier*100%;
          margin: 5px 0;
        }
      }
    }

    .btn.btn-icon,
    .pseudo-checkbox{
      margin: $sizeMultiplier*1px 0;
    }


    .media-mimetype-link div{
      width:$sizeMultiplier *64px;
      height:$sizeMultiplier *64px;
      overflow: hidden;
      img{
        max-width: 100% !important;
      }
    }
    .media-mimetype-icon{
      font-size: $sizeMultiplier * 32px !important;
    }
  }

}
