// ***************//
// *** GLOBALS ***//
// ***************//

//COLORS
$ui-blue : #4a5d65;
$ui-red: #fc685f ;
$ui-green: #3fa372 ;
$ui-gray: #909090 ;
$ui-gray-dark: #25292a ;
$ui-white:  #fff ;
$ui-black:  #000 ;
$ui-orange: #feb000 ;
$ui-yellow: #ffd500 ;
$ui-purple: #7d6198 ;
$ui-teal:   #5bc0de ;
$ui-pink:   #ff5b77 ;

// Colors translations
$ui-brand-default: $ui-gray;
$ui-brand-primary : $ui-blue;
$ui-brand-success: $ui-green;
$ui-brand-info:$ui-purple;
$ui-brand-warning: $ui-orange;
$ui-brand-danger: $ui-red;
$ui-brand-inverse: $ui-gray-dark;

$ui-state-success-bg:                 #ebf6f1;
$ui-state-info-bg: #e7e2f8;
$ui-state-warning-bg:                 #fcf8e3;
$ui-state-danger-bg:                  #fcefec;
$ui-state-primary-bg:                 #d9f0fc;
    $ui-state-light-bg:                   #f4f6f7;

// TYPO
$ui-text-color :                      #37474f  ;
$ui-font-bold  :                      600 ;

$ui-link-color:                       $ui-blue;
$ui-link-decoration:                  none ;
$ui-link-weight:                      $ui-font-bold ;
$ui-link-hover-color:                 $ui-brand-primary ;
$ui-link-hover-decoration:            none ;
$ui-heading-color:                    $ui-text-color;

// decoration
$ui-enable-shadows:                   true;
$ui-box-shadow:                       0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
$ui-input-border-radius:              2px ;

// TOP MENU
$ui-top-menu-bg :                     #38393e; //#f6f8fa  ;
$ui-top-menu-gradient-bg :            linear-gradient(135deg, darken($ui-blue,0%) 0, lighten($ui-blue, 10%) 100%) ; //#f6f8fa  ;

// LEFT MENU
$ui-left-menu-logo :                  #4a5d65 ;
$ui-left-menu-bg :                    #f6f8fa ; //#dfe8ec  ;
$ui-left-menu-sub-bg :                #546972; //
$ui-left-menu-sub-color :             $ui-white;
$ui-left-menu-gradient-bg :           linear-gradient(135deg,$ui-left-menu-bg 0, #fff 100%); //#f6f8fa  ;
$ui-left-menu-text-color :            $ui-blue ; //#263035
$ui-left-menu-separator-color:        #e3e8ed ;
$ui-left-menu-hover-color:            #e3e8ed ;
$ui-left-menu-active-color :          $ui-left-menu-text-color; //#263035
$ui-left-menu-active-background :     $ui-left-menu-separator-color; //#263035
$left-menu-overlay-bg :               rgba(74, 93, 101, 0.51);

// CONTENT WRAPPER
$ui-body-bg :                         #eee; //#f6f8fa  ;
$ui-body-gradient-bg :                linear-gradient(135deg, $ui-body-bg 0, #fff 100%) ; //#f6f8fa  ;

$ui-content-wrapper-bg :              #eee; //#f6f8fa  ;

//CONTENT
$ui-card-bg :                         #ffffff  ;
$ui-card-real-bg :                    #ffffff  ;
$ui-card-separator :                  #d6dee2  ;

//forms
$ui-input-border-radius :             2px  ;
$ui-input-border-color :              rgba(0, 0, 0, 0.2) ;
$ui-input-border-width :              1px ;
$ui-input-background:                 $ui-white;
$ui-custome-forms-color:              $ui-blue;

//btns
$ui-btn-border-radius :               2px  ;
$ui-btn-icon-border-radius :          5px  ;

$ui-btn-success-color :               $ui-white;
$ui-btn-success-bg :                  $ui-blue;

$ui-btn-extra-color :                 $ui-white;
$ui-btn-extra-bg :                    $ui-purple;

$ui-btn-defeat-color :                $ui-white;
$ui-btn-defeat-bg :                   $ui-red;


$ui-btn-icon-color:                   $ui-blue;

//dropdown
$ui-dropdown-bg :                     #ffffff;


$modal-backdrop-bg : #272727;

@mixin bgColorContrast($color, $percent) {
    background-color: darken($color, $percent);
}