$gma-background: white !default;
$gma-color: black !default;
$gma-lines-color: #e0e0e0 !default;

$gma-side-panel-width: 280px !default;
$gma-side-panel-heading: 48px !default;

$gma-list-item-new: #ff3d00 !default;
$gma-list-item-break: #fecb00 !default;
$gma-list-item-done: #05c90e !default;

$gma-text-select: #5e9ef5 !default;

$gma-list-item-padding-top: 10px !default;

$gma-btn-edit-color: #858c92 !default;
$gma-btn-edit-color-hover: black !default;

@mixin font-darker() {
    color: #bababa;
    font-size: 90%;
}

@mixin backdrop-color() {
    background: rgba(0,0,0,0.5);
}