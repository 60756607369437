/**
* header
*/
.btn-icon {

  height: 36px;
  padding: 0.4rem 0.313rem;
  border-radius: 5px;
  cursor: pointer;

  color: $btn-icon-color;
  background-color: $btn-icon-background;
  border-color: $btn-icon-border;

  &.btn-sm {
    height: 30px;
    padding: 6px 5px;
    i {
      font-size: 18px;
      line-height: 18px;
    }
  }

  // Hover and focus styles are shared
  @include hover {
    color: $btn-icon-color;
    background-color: darken($btn-icon-background, 7%);
    border-color: darken($btn-icon-border, 9%);
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0;
    background-color: darken($btn-icon-background, 10%);
    border-color: darken($btn-icon-border, 12%);
    color: $btn-icon-color;

  }

  &.disabled,
  &:disabled {
    background-color: $btn-icon-background;
    border-color: $btn-icon-border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    box-shadow: none !important;
    color: $btn-icon-color;
    background-image: none;
    background-color: darken($btn-icon-background, 15%);
    border-color: darken($btn-icon-border, 15%) !important;
  }

  i {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
  }
  span {
    padding-left: 5px;
    display: inline-block;
    top: -3px;
    position: relative;
  }

  &--close-filters {
    height: 26px;
    padding: 0.3rem 0.22rem;

    i {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &--defeat {
    border: 1px solid $input-border-color;
    @include hover {
      color: $ui-btn-defeat-color;
      background-color: $ui-btn-defeat-bg;
      border-color: $ui-btn-defeat-bg;
    }
  }
  &--default {
    border: 1px solid $input-border-color;
    border-color: lighten($input-border-color, 10%);
    @include hover {
      color: $btn-icon-color;
      background-color: lighten($input-border-color, 5%);
      border-color: lighten($input-border-color, 10%);
    }
  }
  &--circle {
    border-radius: 50% !important;
  }

}

.btn-group-sm {
  .btn-icon {
    height: 26px;
    padding: 0.2rem 0.313rem;

    i {
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
    }
  }
}
