.pagination {
  display: flex;
  // 1-2: Disable browser default list styles
  padding-left: 0; // 1
  list-style: none; // 2
  margin-bottom: 0 !important;
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
    }
  }
  &:last-child {
    .page-link {
      margin-right: 0;
    }
  }

  &.active .page-link {
    z-index: 2;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    box-shadow: $box-shadow;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    cursor: $cursor-disabled; // While `pointer-events: none` removes the cursor in modern browsers, we provide a disabled cursor as a fallback.
    background-color: $pagination-disabled-bg;
  }
}

.page-link {
  position: relative;
  margin: 2px;
  text-align: center;
  border-radius: 2px;
  min-width: $pagination-height;
  height: $pagination-height;
  line-height: $pagination-height;
  padding: 0 0.25rem;
  color: $pagination-color;
  background-color: $pagination-bg;
  display: flex;
  flex-direction:column;
  justify-content:center;
  font-size: 0.9em;
  transition:$transition-base;

  @include hover-focus {
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
  }
}

