.nav-header-menu {
  border-top: 1px solid rgba($black,.125);
  margin-top: $card-spacer-x;
  margin-bottom: -$card-spacer-x;
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
  position: relative;
  z-index: 80;
  overflow: hidden;
  overflow-x: auto;

  .nav-link {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
    border-radius-top-left:$border-radius ;
    border-radius-top-right:$border-radius ;
    border-bottom:2px solid transparent;
    color: $ui-text-color;

    white-space: nowrap;

    &:hover{
      color: $ui-link-color;
    }

  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $ui-link-color;
    cursor: default;
    border-color: $ui-link-color;
  }
}