.tour-navs {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    button {
        margin: 5px 20px;
    }
}

.popover-content {
    word-break: break-all;
}

.gma-btn {
    color: $gma-btn-edit-color;
    background-color: inherit;
    font-size: 20px;
    border: 0;
    outline: none;
    margin: 0;
    padding: 0 2px;
    cursor: pointer;

    &:hover {
        color: $gma-btn-edit-color-hover;
    }
}

hr.gma-line {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid $gma-lines-color;
}

.cursor-pointer {
    cursor: pointer
}