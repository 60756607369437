.loading-layer {
  position: relative;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.3;
    background: $ui-state-light-bg;
    z-index: 101;

  }
  &:not(.loading-layer__bar) {
    &:after {
      font: normal normal normal 48px/1 "Material Design Icons";
      content: mdi('loading');
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -24px;
      margin-left: -24px;
      -webkit-animation: #{$mdi-css-prefix}-spin 1s infinite linear;
      animation: #{$mdi-css-prefix}-spin 1s infinite linear;
      z-index: 102;
    }
  }
  &__bar {
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 4px;
      right: 0;
      background-color: $brand-primary;
      animation: loading .5s linear infinite;
      z-index: 102;
    }
  }

}
@keyframes loading {
  from {left: 50%; width: 0;z-index:100;}
  66.3333% {left: 0; width: 100%;z-index: 10;}
  to {left: 0; width: 100%;}
}