/***
* Tabele
*
*
***/

.card-table .card-table__content--md tr td .form__checkbox {
  display: flex;
}

.scrolled-up .card-table__content tfoot {
  margin-top: -20px;
}

.card-table {
  overflow-x: hidden;

  &__content {
    width: 100%;

    tfoot {
      margin-top: 0;
      transition: margin-top .5s;
      background-color: $ui-card-real-bg;
    }

    th {
      font-weight: 600;
      border-bottom: 1px solid  $ui-card-separator;
      position: relative;
      label{
        display: block;
        margin: 0;
        position: relative;

        &.activeSorting{
          cursor: pointer;
          padding-right: 22px !important;
        }

      }

      &:hover{

        .activeSorting{
          background: $ui-state-light-bg;
          cursor: pointer;
        }

        .card-table__sorting{
          color: $black;
        }

        .card-table__column-resize{
          background: #bbd8ff;

          &:hover{
            background: #83beff;
          }
        }

      }

      &:first-of-type,
      &:last-of-type{
        white-space: nowrap;
        width: 1px;
        background: transparent !important;
        cursor: auto;
        &:hover{
          background: transparent !important;
        }
      }

    }

    tr {
      &.trigger-hover,
      &:hover {
        td {
          background:  $ui-state-light-bg;

          .ico-drag-vertical{
            opacity: 1;

            &:hover{
              background-color: $ui-card-separator;
              border-radius: 4px
            }
          }
        }
      }
      td{
        border-bottom: 1px solid  $ui-card-separator;
        .ico-drag-vertical{
          opacity: 0;
          font-size:20px;
          cursor: pointer;
        }
      }
      td:first-of-type,
      td:last-of-type{
        padding-left: 5px;
      }
    }


  }

  @include tableDensity(&+'__content--sm',0.8 , 0.75);
  @include tableDensity(&+'__content--md',1 , 1);
  @include tableDensity(&+'__content--xl',1 , 1.5);

  &__sorting{
    display: inline-block;
    height: 12px;
    width: 12px;
    position: absolute;
    line-height: 0px;
    color: $gray-lighter;
    right: 10px;
    top: 50%;
    margin-top: -6px;



    .ico-menu-up{
      position: absolute;
      top:0;
      left:0;
    }
    .ico-menu-down{
      position: absolute;
      top:10px;
      left:0;
    }

    &--up{
      .ico-menu-up{
        color: $black;
      }
    }
    &--down{
      .ico-menu-down{
        color: $black;
      }
    }
  }

  &__column-resize{
    display: none; // @todo

    position: absolute;
    right: 0;
    width: 5px;
    top:0;
    bottom: 0;
    background: transparent;
    cursor: col-resize;
  }


  .btn-multi-select{
      background: $ui-input-background;
      border: 1px solid $ui-input-border-color;
      box-shadow: 0 0 0;
      padding: $btn-padding-y-sm $btn-padding-x-sm;

      .pseudo-checkbox{
        background: transparent;
        border: 1px solid $ui-input-border-color;
        display: inline-block;
        border-radius: 2px;
        width: 20px;
        height: 20px;
        position: relative;
        top: 0;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
  }

  &__actionMenu{
    margin:0 -15px;

    & > a{
      min-width: 120px;
    }
  }
}



@include table-row-variant(disabled, lighten($ui-state-light-bg,50%));
.table-disabled td > *:not(.dropdown){
  opacity: 0.5;
}


.dropdown-item {
  &:active {
    .dropdown-item-name {
      color: $dropdown-link-active-color;
    }
  }
  .dropdown-item-delete {
    @include hover-focus {
      color: $dropdown-link-color;
      text-decoration: none;
      @include bgColorContrast($dropdown-link-hover-bg, 10%);
    }
  }
}