//input file & dropbox
.upload-file__box {

  .upload-file{
    position: relative;

    &__area {
      position: relative;
      overflow: hidden;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      // white-space: nowrap;
      text-align: center;
      padding: 2rem 1rem;
      width: 100%;
      // min-width: 280px;
      border: 3px dashed $ui-input-border-color;
      p,span{
        color:$ui-text-color !important;
        display: block;
        color: rgba($ui-text-color, 0.75);
      }

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        -ms-filter: 'alpha(opacity=0)';
        font-size: 200px;
        direction: ltr;
        cursor: pointer;
      }

      input[type=file] {
        display: block;
        align-items: baseline;
      }
    }

    &__btn{
      display: block;
      margin: 10px auto 0;
      cursor: pointer;
    }

    &__list {
      list-style: none;
      padding: 5px 0;
      word-break: break-all;
    }
  }
}