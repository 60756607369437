.mCSB_scrollTools {
  -webkit-transition: all .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: all .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: all .2s ease-in-out, background-color .2s ease-in-out;
  transition: all .2s ease-in-out, background-color .2s ease-in-out;

  .mCSB_dragger .mCSB_dragger_bar, .mCSB_buttonUp, .mCSB_buttonDown, .mCSB_buttonLeft, .mCSB_buttonRight {
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  }
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
  .mCSB_dragger_bar, .mCSB_draggerRail {
    -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  }
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
  .mCSB_dragger_bar, .mCSB_draggerRail {
    -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  }
}
/*
.mCSB_scrollTools {
  opacity: 0.75;
}

.mCS-autoHide > .mCustomScrollBox {
  > .mCSB_scrollTools, ~ .mCSB_scrollTools {
    opacity: 0;
  }
}

.mCustomScrollbar > .mCustomScrollBox {
  > .mCSB_scrollTools.mCSB_scrollTools_onDrag, ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
    opacity: 1;
  }
}

.mCustomScrollBox:hover {
  > .mCSB_scrollTools, ~ .mCSB_scrollTools {
    opacity: 1;
  }
}
*/
.mCSB_inside > .mCSB_container {
  margin-right: 6px !important;
}
.mCS-autoHide:hover > .mCustomScrollBox {
  > .mCSB_scrollTools, ~ .mCSB_scrollTools {
    opacity: 1;
  }
}

.mCSB_scrollTools {
  width: 6px;
  margin: 5px 0;

  &:hover{
      width:6px;
  }
  .mCSB_draggerRail {
    background-color: $white;
    width:100%;
    border-radius: 0;
  }
  .mCSB_dragger {
    width:100%;
    .mCSB_dragger_bar {
      background-color: $gray-lighter;
      width:100%;
      border-radius: 0;
    }
    &:hover .mCSB_dragger_bar {
      background-color: darken($gray-lighter, 5%)
    }
    &:active .mCSB_dragger_bar, &.mCSB_dragger_onDrag .mCSB_dragger_bar {
      background-color: darken($gray-lighter, 5%)
    }
  }
  .mCSB_buttonUp, .mCSB_buttonDown, .mCSB_buttonLeft, .mCSB_buttonRight {
    opacity: 0.4;
  }
  .mCSB_buttonUp:hover, .mCSB_buttonDown:hover, .mCSB_buttonLeft:hover, .mCSB_buttonRight:hover {
    opacity: 0.75;
  }
  .mCSB_buttonUp:active, .mCSB_buttonDown:active, .mCSB_buttonLeft:active, .mCSB_buttonRight:active {
    opacity: 0.9;
  }
}