.media-field {
  &__item {

    display: flex;
    align-items: center !important;

    & + &,
    & + &-add {
      margin-top: $card-spacer-x;
    }
  }

  &__file {
    width: 128px;
    height: 128px;
    display: flex;
    border: 1px solid $ui-input-border-color;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer;
    transition: $transition-base;
    padding: 4px;
    margin-right: 1rem;

    &--select {
      border-width: 2px;
      border-style: dashed;
      color: $link-color;
      font-weight: $ui-link-weight;
    }

    * {
      text-align: center;
    }

    &:hover {
      border-color: $link-color;
    }

  }
  &__name {
    font-weight: $ui-font-bold;
  }

  &--gallery {
    .file {
      &-list {
        display: flex;
        flex-wrap: wrap !important;

      }
    }
    .media-field {
      &__item {
        position: relative;
        margin-top: 0 !important;
        margin-bottom: $grid-gutter-width-base;
        &:hover {
          .media-field__name {
            display: block;
          }
        }
      }
      &__name {
        display: none;
        position: absolute;
        left: 0;
        right: 1rem;
        bottom: 0;

        p {
          padding: $grid-gutter-width-base/2 !important;
          margin: 0 !important;
          text-align: center;
        }

        .file-name {
          display: none;
        }
      }
    }
  }

}

.media-field__item.gu-mirror {
  .media-field__file {
    width: 128px;
    height: 128px;
  }
  .media-field__name {
    display: none;
  }
}