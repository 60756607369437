form.filters {
  .bs-searchbox, .bs-actionsbox.dropdown {
    display: none !important;
  }
}

form:not(.filters) .form, table .form, .custom-form .form {

  &__radio {
    margin: 0.5rem;
    position: relative;

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .form__radio-label {
        cursor: pointer;
        &:before {
          content: '';
          background: $ui-input-background;
          border-radius: 100%;
          border: 1px solid lighten($gray, 12%);
          display: inline-block;
          width: 20px;
          height: 20px;
          position: relative;
          top: 0;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
        }
      }
      &:checked {
        + .form__radio-label {
          &:before {
            background-color: $ui-custome-forms-color;
            box-shadow: inset 0 0 0 3px $gray-lightest;
          }
        }
      }
      &:focus {
        + .form__radio-label {
          &:before {
            outline: none;
            border-color: $ui-custome-forms-color;
          }
        }
      }
      &:disabled {
        + .form__radio-label {
          &:before {
            box-shadow: inset 0 0 0 3px $gray-lightest;
            border-color: darken($gray-lightest, 25%);
            background: darken($gray-lightest, 25%);
          }
        }
      }
      + .form__radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__checkbox {
    margin: 0.5rem;
    cursor: pointer;
    position: relative;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;

      + .form__checkbox-label {
        cursor: pointer;

        &:before {
          content: '';
          background: $ui-input-background;
          border: 1px solid $input-border-color;
          display: inline-block;
          border-radius: 2px;
          width: 20px;
          height: 20px;
          position: relative;
          top: 0;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
        &:after {
          content: '';
          display: block;
          width: 8px;
          height: 14px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          transition: all 250ms ease;
          position: absolute;
          top: 1px;
          left: 6px;
          opacity: 0;
        }
      }
      &:checked {
        + .form__checkbox-label {
          &:before {
            background-color: $ui-custome-forms-color !important;
            border-color: $ui-custome-forms-color !important;
          }
          &:after {
            border-color: $white !important;
            opacity: 1;
          }
        }
      }
      &:focus {
        + .form__checkbox-label {
          &:before {
            outline: none;
            border-color: $ui-custome-forms-color;
          }
        }
      }
      &:disabled {
        + .form__checkbox-label {
          &:before {
            border-color: darken($gray-lightest, 10%);
            background-color: darken($gray-lightest, 10%);
          }
          &:after {
            border-color: $white;
          }
        }
      }
      + .form__checkbox-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }

    &--naked {
      margin: 0;
      label {
        margin: 0;
      }
    }

    &--sm {
      input[type="checkbox"] {

        + .form__checkbox-label {
          bottom: -1px;
          position: relative;

          &:before {
            width: 18px;
            height: 18px;
          }
          &:after {
            width: 7px;
            height: 11px;
            top: 2px;
            left: 6px;
          }
        }
      }
    }
  }

  &__switch {
    margin: 0.5rem;
    position: relative;

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      cursor: pointer;

      + .form__switch-label {
        cursor: pointer;
        position: relative;
        margin: 0;

        span:after {
          content: attr(data-unchecked-title);
          display: inline-block;
        }

        &:before {
          display: inline-block;
          content: '';
          width: 30px;
          height: 10px;
          background: $ui-input-border-color;
          border-radius: 5px;
          margin-right: 5px;
        }
        &:after {
          content: '';
          background: $gray-lightest;
          width: 18px;
          height: 18px;
          display: inline-block;
          position: absolute;
          border-radius: 8px;
          left: 0;
          margin-top: 1px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 16px rgba(0, 108, 181, 0);
          transition: all 250ms ease;
        }
        + input[type="radio"] {
          position: absolute;
          left: 0px;
          top: 1px;
          bottom: 1px;
          display: block;
          width: 100%;
          height: 20px;
          z-index: -1;
          cursor: pointer;
        }
      }
      &:checked {
        + .form__switch-label {

          + input[type="radio"] {
            z-index: 100;
          }
          span:after {
            content: attr(data-checked-title);
            display: inline-block;
          }
          &:after {
            background-color: $ui-custome-forms-color !important;
            margin-left: 12px;
          }
        }
      }
      &:focus {
        //@TODO
      }
      &:disabled {
        //@TODO
      }
    }

    &--disabled {
      pointer-events: none;
      input[type="radio"]:valid {
        position: absolute;
        opacity: 0;
        + label.form__switch-label:after {
          background: $gray-light !important;
        }
      }
    }

    &__right {
      text-align: right;
      input[type="checkbox"] {
        + .form__switch-label {
          padding-right: 42px;

          &:before {
            display: inline-block;
            content: '';
            width: 30px;
            height: 10px;
            background: $gray-lighter;
            border-radius: 5px;
            margin-right: 5px;
            position: absolute;
            right: 0;
            top: 6px;
          }
          &:after {
            content: '';
            background: $gray-lightest;
            width: 18px;
            height: 18px;
            display: inline-block;
            border-radius: 8px;
            margin-top: 1px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 16px rgba(0, 108, 181, 0);
            transition: all 250ms ease;
            position: absolute;
            right: 18px;
            left: auto;
          }
        }

        &:checked {
          + .form__switch-label {

            &:after {
              right: 4px;
            }
          }
        }
      }
    }

  }

  &__select {

    &.dropup,
    &.dropdown {
      position: static !important;
    }

    .dropdown-menu {

      .selected {
        color: $dropdown-link-hover-color;
        text-decoration: none;
        background-color: darken($ui-state-light-bg, 7%);
      }

      .bs-searchbox {
        padding: 0;
        margin: 0 0 5px;

        .form-control {
          font-size: $font-size-xs;
        }
        &.form__select__multiple {
          margin-right: 45px;
        }
      }
      .bs-actionsbox.dropdown {
        padding: 0;

        .btn-icon {
          position: absolute;
          top: -38px;
          right: 0px;
        }
        .dropdown-menu {
          min-width: initial;
          bottom: auto;
          margin-bottom: auto;
        }
      }
      .hidden {
        display: none;
      }

      .dropdown-item {
        padding: 5px 0.75rem;
      }


      &-resizer{
        cursor:n-resize;
        width:100%;
        height:16px;
        background:$ui-state-light-bg;
        border-width: 0 1px 1px 1px;
        border-color: $input-border-color;
        border-style: solid;
        padding-top: 5px;
        transition: $transition-base;

        &:hover{
          background: darken($ui-state-light-bg, 05%);
        }


        &:after,&:before{
          display: block;
          margin: -1px auto 0;
          padding-top: 2px;
          content: '';
          border-width: 1px 0;
          border-color: $ui-gray;
          width: 20px;
          height: 1px;
          border-style: solid;
      }


      }
    }
    .dropdown-toggle span {
      margin-top: 2px;
      margin-bottom: -2px;
    }
    &__list {

      &-big {
        > .dropdown-menu {
          > .dropdown-menu.inner {
            height: 320px !important;
          }
        }
      }

      > .dropdown-toggle {
        display: none;
      }
      > .dropdown-menu {
        &.open {
          background-color: transparent !important;
        }
        display: block !important;
        position: static;
        box-shadow: 0 0 0;
        margin: 0;
        border-width: 0;
        overflow: visible !important;

        > .dropdown-menu.inner {
          display: block !important;
          overflow-y: auto;
          height: 120px;
          border: $dropdown-border-width solid $dropdown-border-color;
          border-radius: $border-radius;
        }
        .dropdown-item[aria-disabled="true"], .dropdown-item[disabled="disabled"]{
          pointer-events: none;
          span{
            color: #bcbcbc;
          }
        }
      }
      a {
        outline: 0 !important;
      }
    }
  }

  &__tooltip {
    color: $gray-light p {
      margin: 0;
    }
  }

  &__radio-image {
    min-width: 180px;
    width: 25%;
    padding: $grid-gutter-width-base/2;

    &__wrapper {
      margin: -$grid-gutter-width-base/2;
    }

    .form__radio-label {
      text-align: center;
      border: 1px solid $ui-input-border-color;
      transition: $transition-base;
      cursor: pointer;
      padding: $grid-gutter-width-base/2;
    }
    label {
      height: 100%;
      padding-bottom: 6px;
      width: 100%;
    }
    input {
      &:checked {
        + .form__radio-label {
          border-color: $ui-link-color;
          box-shadow: inset 0px 0px 0px 3px $ui-link-color;
        }
      }

    }

    &:not(.disabled) {
      .form__radio-label {
        &:hover {
          border-color: $ui-link-color;
          box-shadow: inset 0px 0px 0px 1px $ui-link-color;
        }
      }
    }

    &.disabled {
      .form__radio-label {
        cursor: not-allowed !important;
      }
    }
  }

  &__link {
    .form__link-slot {
      line-height: 30px;
      font-size: $font-size-base *0.8;

      padding-bottom: 0;
      .btn-sm{
        position: relative;
        top:-3px;
        bottom:-3px
      }
      .form-control{
        margin: 0;
      }
    }
    [data-suggest-input] {
      width: auto;
      display: inline-block;
    }
  }
}

.bootstrap-select.form__select__list.btn-group{
  .dropdown-menu li {

    a:before {
      content: '';
      background: $ui-input-background;
      border-radius: 100%;
      border: 1px solid $input-border-color;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;

    }
    a:after {
      content: '';
      background: $ui-input-background;
      display: inline-block;
      border-radius: 20px;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 16px;
      top: 9px;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
    &.selected{
      a:before {
        opacity: 1 !important;
      }
      a:after{
        background-color: $ui-custome-forms-color !important;
        border-color: $ui-custome-forms-color !important;
        opacity: 1 !important;
      }
    }

  }
  &.form__select__list__multiple{
    .dropdown-menu li {

      a:after {
        content: "\F12C";
        font: normal normal normal 16px/1 "Material Design Icons";
        padding-right: 10px;
        margin-left: -2px;
        top:7px;
        background: transparent !important;
        opacity: 0;
        color:$white !important;
      }
      a:before {
        border-radius: 2px;
      }

      &.selected{
        a:before {
          opacity: 1 !important;
          background-color: $ui-custome-forms-color !important;
          border-color: $ui-custome-forms-color !important;
        }
      }
    }
  }
}

.repeatable-fc{
  display: flex;

  &:hover{
    .repeatable-fc__actions {
      & > *{
        opacity: 1;
        transition: $transition-base;
      }
    }

  }

  &__line-number{
    background: $ui-state-light-bg;
    border-right: $card-border-width solid $card-border-color;
    border-bottom: $card-border-width solid $card-border-color;
    padding:  $card-spacer-x;
    display: flex;
    align-items: center!important;
  }
  &__form{
    flex-grow: 1!important;
  }
  &__actions {
    background: $ui-state-light-bg;
    border-left: $card-border-width solid $card-border-color;
    border-bottom: $card-border-width solid $card-border-color;
    padding: $card-spacer-x/2;
    display: flex;
    align-items: center!important;
    & > * {
      opacity:0
    }
  }
}

.password-verification-messages {
  display: -ms-flex;
  display: flex;
  flex-direction: column;

  li {
    position: relative;
    display: -ms-inline-flex;
    display: inline-flex;
    align-items: center;
    line-height: 1.25;

    &.success{
        i{
            color: $ui-green;
            font-size: 18px;
            margin-right: 3px;
        }
    }
    &.error{
        i{
            color: $ui-gray;
            font-size: 18px;
            margin-right: 3px;
        }
    }
  }
}

.progress-password {
  background-color: $ui-white;

  .verification-progress {
    height: 3px;
    transition: width .25s ease;

    &--weak {
      background-color: $ui-red;
    }

    &--average {
      background-color: $ui-orange;
    }

    &--strong {
      background-color: $ui-green;
    }
  }
}