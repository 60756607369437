.homepage-section {

  position: relative;
  margin-right: 30px;
  border: 1px solid transparent;
  min-height: 100px;


  &__list{
    max-width: 450px;
    margin: 0 auto;
  }


  &__header {
    padding: $card-spacer-x;
    position: absolute;
    top: 0;
    left: 0;
    right: -30px;
    bottom: 0;
    opacity: 0;
    transition: $transition-base;
  }

  &:hover {
    cursor: move;
    border-color: $ui-state-light-bg;


    .homepage-section__header {
      opacity: 1;
      background: rgba($ui-state-light-bg, 0.9);
    }
  }

  + .homepage-section {
    //border-top: 1px solid $ui-input-border-color
  }
  &:last-of-type {
    margin-bottom: $card-spacer-x;
  }


  &__add-item{
    padding: 1rem;

    .embed-responsive{
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }


}