@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin blink-variant($name, $color) {

  .blink.badge-#{$name} {
    -webkit-animation: blink-#{$name} 3s infinite;
    -moz-animation:    blink-#{$name} 3s infinite;
    animation:         blink-#{$name} 3s infinite;
    transition: $transition-base;

  }

  //@include keyframes(blink-#{$name}) {
  //  0%   { background-color: $color; color: #ffffff;  }
  //  80% { background-color: $color; color:  #ffffff }
  //  85% { background-color: transparent; color:  $color }
  //  100% { background-color: $color; color:  #ffffff }
  //}

  @include keyframes(blink-#{$name}) {
    0%   { background-color: rgba($color, 0.1); color: $color;  }
    80%  { background-color: rgba($color, 0.1); color:  $color }
    85%  { background-color: $color; color:  $white }
    100% { background-color: rgba($color, 0.1); color:  $color }
  }

}

@include blink-variant(warning, $brand-warning)
@include blink-variant(success, $brand-success)
@include blink-variant(info, $brand-info)
@include blink-variant(primary, $brand-primary)
@include blink-variant(success, $brand-success)
@include blink-variant(default, $gray-lighter)
@include blink-variant(danger, $brand-danger)


