.twoDimensionalTable {
    .card {
        box-shadow: unset;
        h4 {
            pointer-events: none;
        }
        i {
            pointer-events: none;
        }
    }
    div#tableCollapse {

        background-color: white;
        table {
            padding-top: 0;
            @media(max-width: 768px) {
                overflow-x:scroll;
            }
            thead {
                tr {
                    th {
                        border: 0;
                        text-align: center;
                    }
                }
            }
            tbody {
                tr {
                    th {
                        vertical-align: middle;
                    }
                    td {
                        vertical-align: middle;
                        input {
                            border: 1px solid rgba(0,0,0,.2);
                            border-radius: 2px;
                            padding: 0.35rem 0.5rem;
                            width: 100%;
                            min-width: 50px;
                        }

                        &.has-danger {
                            input {
                                border: 1px solid $red;
                            }
                        }
                    }
                }
            }
        }
    }
}