.autocomplete-suggestions {
  background: $ui-card-real-bg;
  overflow: auto;
  width: auto !important;
  min-width: 200px;
  color: $ui-text-color;
  margin: 5px 0;
  box-shadow: $box-shadow;
  padding: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.15rem;
}

.autocomplete-suggestion {
  padding: 2px 13px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background: rgba(75, 99, 107, 0.2);
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #ff5962;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}