// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.display-4 .badge{
font-size: 0.85*$font-size-base;
}

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  color: $badge-color;
  margin-top: 2px ;
  margin-bottom: 2px ;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border:0 none;
  @include border-radius();

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }


  &:hover{
    opacity: 0.9;
  }


  [class^="ico-"],
  [class*=" ico-"] {
    font-size: 18px;
    line-height: 10px;
    bottom: -2px;
    position: relative;
  }

}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// scss-lint:disable QualifyingElement
// Add hover effects, but only for links
a.badge {
  @include hover-focus {
    color: $badge-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }
}
// scss-lint:enable QualifyingElement

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  //@include border-radius($badge-pill-border-radius);
}

.badge-dot{
  width:15px;
  height:15px;
  padding:0 !important;
  position: relative;
  top: -2px;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

.badge-default {
  @include badge-variant($badge-default-bg);
}

.badge-primary {
  @include badge-variant($badge-primary-bg);
}

.badge-success {
  @include badge-variant($badge-success-bg);
}

.badge-info {
  @include badge-variant($badge-info-bg);
}

.badge-warning {
  @include badge-variant($badge-warning-bg);
}

.badge-danger {
  @include badge-variant($badge-danger-bg);
}


button.badge-default,
.badge-default[href]  {
  @include badge-variant-active($badge-default-bg);
}

button.badge-primary,
.badge-primary[href]  {
  @include badge-variant-active($badge-primary-bg);
}

button.badge-success,
.badge-success[href]  {
  @include badge-variant-active($badge-success-bg);
}

button.badge-info,
.badge-info[href]  {
  @include badge-variant-active($badge-info-bg);
}

button.badge-warning,
.badge-warning[href]  {
  @include badge-variant-active($badge-warning-bg);
}

button.badge-danger,
.badge-danger[href]  {
  @include badge-variant-active($badge-danger-bg);
}
