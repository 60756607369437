.advanced-filter-select {
    .dropdown-toggle {
        background-color: #556b74;
        color: #fff;
        padding: 10px 25px 10px 10px!important;
    }
    .dropdown-menu {
        padding: 0; 
        margin: 0;
        li {
            cursor: pointer;
            border-bottom: 1px solid #f4f6f7;
            transition: .1s;

            & a {
                outline: none;
                display: block;
                padding: 10px;
            }

            &.selected {
                background-color: #556b74;
                
                .text {
                    color: #fff;
                }
            }

            &:hover {
                background-color: #556b74;
                
                .text {
                    color: #fff;
                }
            }
        }
    }
    

    &.mobile {

        select {
            width: 220px!important;
            left: 0!important;
        }

        .dropdown-toggle {
            pointer-events: none;
        }
    }

}

