$small-ratio :0.8;


@include media-breakpoint-down(sm){

  //reboot
  body {
    font-size: $font-size-base*$small-ratio;
  }


  //typo
  h1, .h1 { font-size: $font-size-h1*$small-ratio; }
  h2, .h2 { font-size: $font-size-h2*$small-ratio; }
  h3, .h3 { font-size: $font-size-h3*$small-ratio; }
  h4, .h4 { font-size: $font-size-h4*$small-ratio; }
  h5, .h5 { font-size: $font-size-h5*$small-ratio; }
  h6, .h6 { font-size: $font-size-h6*$small-ratio; }


  .form-control{
    font-size: $font-size-base*$small-ratio;
  }

  .btn-select{
    font-size: $font-size-base*$small-ratio;
  }
  .dropdown-menu {
    font-size: $font-size-base*$small-ratio;
  }
  .btn{
    font-size: $font-size-base*$small-ratio;
  }

  .btn-icon {
    height: 32px;
    i {
      font-size: 20px;
      line-height: 20px;
      font-size: 20px;
    }
  }

  #navbar-left .nav-pills .nav-head, #navbar-left .nav-pills .nav-link{
    font-size: $font-size-base*$small-ratio;
  }
  #navbar-left .nav-pills .nav-head i, #navbar-left .nav-pills .nav-link i{
    font-size: 16px;
  }
  .menu-aside > ul > .nav-link{
    min-height: 24px;
  }

  .menu-aside__submenu__header{
    font-size: 18px;
  }
  .menu-aside__check--submenu:checked ~ .menu-aside__submenu__list > li > .nav-link,
  .menu-aside__check--submenu:checked ~ .menu-aside__submenu__list > li > .nav-head{
    font-size: 13.5px !important;
  }
  .menu-aside__check--submenu:checked ~ .menu-aside__submenu__list > li ul .nav-link{
    font-size: 12px !important;

  }

  .card-table {
    @include tableDensity(&+'__content--sm', 0.6, 0.75);
    @include tableDensity(&+'__content--md', 0.8, 1);
    @include tableDensity(&+'__content--xl', 0.8, 1.5);
  }

}

/*
 * @todo do dokończenia
 */