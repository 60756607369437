// Timeline
.timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0 10px;

  &:before {
    content: "";
    width: 3px;
    height: 100%;
    background: $ui-input-border-color;
    left: 50%;
    top: 0;
    position: absolute;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
    width: 100%;
  }

  .timeline__item {
    margin-bottom: 10px;
    position: relative;

    &:after, &:before {
      content: '';
      display: block;
      width: 100%;
      clear: both;
    }

    .timeline__icon {
      background: rgba($brand-primary, 1);
      width: 38px;
      height: 38px;
      position: absolute;
      top: 2px;
      left: 50%;
      overflow: hidden;
      margin-left: -18px;
      border-radius: 50%;
      text-align: center;
      line-height: 38px;
      font-size: 20px;
      color: $white;
    }

    .timeline__content {
      width: 45%;
      background: $ui-state-light-bg;
      padding: 10px 15px;
      border: 1px solid $ui-input-border-color;
      border-radius: 5px;
      transition: all .3s ease;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        left: 45%;
        top: 15px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid $ui-input-border-color;
      }

      &--right {
        float: right;

        &:before {
          content: '';
          right: 45%;
          left: inherit;
          border-left: 0;
          border-right: 7px solid $ui-input-border-color;
        }
      }
    }

    .timeline__date {
      background: $ui-input-border-color;
      text-align: right;
      margin: 10px -15px -10px -15px;
      padding: 3px 15px;
      font-size: 12px;
    }
  }
}

@include media-breakpoint-down(md) {

  .timeline {

    margin: 30px 30px 30px 60px ;
    padding: 0px;
    width: auto;
    &:before {
      left: -30px;
    }
    .timeline__item {
      .timeline__content {
        width: auto !important;
        float: none !important;

        &:before, &.right:before {
          left: 0 !important;
          margin-left: -6px;
          border-left: 0;
          border-right: 7px solid $ui-input-border-color;
        }
      }

      .timeline__icon {
        left: -30px;
      }
    }
  }

}

/*
@media screen and (max-width: 768px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
    &:before {
      left: 0;
    }

    .timeline-item {
      .timeline-content {
        width: 90%;
        float: right;

        &:before, &.right:before {
          left: 10%;
          margin-left: -6px;
          border-left: 0;
          border-right: 7px solid $timeline-color;
        }
      }

      .timeline-icon {
        left: 0;
      }
    }
  }
}
*/