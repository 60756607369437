.border-none {
  border: none !important;
}

.push-bottom {
  padding-bottom: 20px;

  &--md {
    padding-bottom: 40px;
  }

  &--lg {
    padding-bottom: 60px;
  }
}

.push-top {
  padding-top: 20px;
}

//links

.rotate {
  transform: rotate(90deg);
}

.bg-transparent{
  background-color: transparent !important;
}

.attention-box{
  background: $ui-state-warning-bg;
  padding: 1rem 1.5rem;
  margin: 1rem auto;
  display: flex;
  max-width: 500px;
  //line-height: 2rem;
  .ico-alert{
    font-size: 2rem;
    opacity: 0.7;
    margin-right: 1rem;
  }
  strong{
    align-self: center!important;
  }
}
.table-row-no-hover{
  &, &:hover{
    td{
      background: transparent !important;
    }
  }
}