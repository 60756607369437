// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  // Hover and focus styles are shared
  @include hover {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);
  }
  &:focus,
  &.focus {
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);
    color:$color;
    // Avoid using mixin so we can pass custom focus shadow properly
    @include box-shadow($btn-focus-box-shadow);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color;
    background-image: none; // Remove the gradient for the pressed/active state

    @include box-shadow($btn-active-box-shadow);

    background-color: lighten($background, 15%);
    border-color: lighten($border, 20%);
  }
}

@mixin button-outline-variant($color, $color-hover: #fff) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: $color;

  @include hover {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  @include border-radius($border-radius);
}
