.btn-circle{

  border-radius: 50%;
  padding: 0;

  i{
    font-size: 24px;
    line-height: 40px;
    width:40px;
    height: 40px;
  }

}