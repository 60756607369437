.#{$prefix}-text-format {
    &-strong {
        font-weight: bold;
    }
    &-italic {
        font-style: italic;
    }

    $aligns: left right center;
    @each $align in $aligns {
        &-#{$align} {
            text-align: #{$align}
        }
    }
}