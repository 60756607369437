.modal--media {
  
  &.modal-dialog{
    width:90vw;
    max-width: 1528px !important;
  }

  .modal-body{
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-header{
    padding-bottom: $modal-header-padding/2;
  }

  .nav-tabs{
    padding: 0 $modal-header-padding;
  }
}

.modal-body {
  &-auto {
    &--with-footer {
      max-height: calc(100vh - 180px);
      overflow: auto;
    }
    &--without-footer {
      max-height: calc(100vh - 120px);
      overflow: auto;
    }
  }
  &-full {
    &--with-footer {
      height: calc(100vh - 180px);
      overflow: none;
    }
    &--without-footer {
      height: calc(100vh - 120px);
      overflow: none;
    }
  }
}

.complexModal {
  overflow: unset !important;
}

.media-panel{

  @include flexbox();
  @include flex-direction(row);
  @include align-items(top);
  @include justify-content(center);


  border: 1px solid $gray-lighter;

  &__listing{
    width: 100%;
  }

  &__details{
    width: 20%;
  }

  &__header{
    padding: 10px;
    border-bottom: 1px solid $gray-lighter;
    min-height: 50px;
    @include flexbox();
    @include align-items(center);

    &-item{
      max-width: 160px;
      margin-right: 10px;

      &:last-of-type{
        margin-right: 0;
        max-width: 200px;
        margin-left: auto;
      }

      .form-control{
        line-height: 18px;
      }
    }
  }

  &__content{
    padding: 10px;
    min-height: 400px;
  }


  &__footer{
    padding: 10px;
    border-top: 1px solid $gray-lighter;
    @include flexbox();
    @include align-items(center);
  }



  &__listing + &__details{
    border-left: 1px solid $gray-lighter;
  }

}