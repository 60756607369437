$gma-width: 260px;

#gma-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: $gma-width;
  margin-right: -#{$gma-width};
  transition: all .5s;
  box-shadow: $box-shadow;
}
#gma-control {
  &:checked {
    & ~ #content-wrapper {
      margin-right: $gma-width;
    }

    & ~ #navbar-top {
      margin-right: $gma-width;
    }

    & ~ #gma-wrapper {
      margin-right: 0;
    }
  }
}