.ui-panel {

  flex-direction: column!important;
  display: flex;

  &:not(.on-active) {
    border: 1px solid #DDD;
  }
  width: 100%;


  &.is-on-acitve{
    border: 1px solid #DDD !important;
    .ui-panel + .ui-panel {
      border-left: 1px solid #DDD !important;
    }
  }

  &__control {
    border-bottom: 1px solid #dfe5e8;
    background: #f4f6f7;
    padding: 0 0.5rem;
    display: flex;

  }

  &__body {
    padding: 0.5rem;

    &--row {
      display: flex;

      .ui-panel + .ui-panel {
        border-left-width: 0;
      }

    }

    + .ui-panel__control {

      border-bottom: 0;

      border-top: 1px solid #dfe5e8;
    }

    .trumbowyg-box {
      margin: 0;
    }
  }

  &__btn {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 35px;
    padding: 1px 6px !important;
    margin-bottom: 1px;
    overflow: hidden;
    border: none;
    cursor: pointer;
    background: none;
    vertical-align: middle;
    transition: background-color 150ms, opacity 150ms;
    line-height: 35px;
    cursor: pointer;
    text-align: center;

    &.active,
    &:hover {
      background-color: #FFF;
      outline: none;
    }

  }

  &__image-holder{
    color: $ui-text-color;
    font-size:100px;
    display: block;
    text-align: center;
    opacity: 0.4;
    @include media-breakpoint-down('sm') {

      font-size: 60px;
    }
  }

}